import React, { useEffect, useState } from 'react';
import { Typography, Input, Form } from 'antd';
import { MAXIMUM_500_CHARACTERS } from 'constant';
import { TServiceNote } from 'models/ServiceNote';
import styles from './contentEditable.module.scss';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type Props = {
  focusingID?: number;
  note: TServiceNote;
  error: string;
  onEscape: () => void;
  onEditChange: (value: string) => void;
  onEnter: () => void;
  onErrorChange: (value: string) => void;
};

const ContentEditable: React.FC<Props> = ({
  focusingID,
  note,
  error,
  onEscape,
  onEditChange,
  onEnter,
  onErrorChange
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(note.text);

  const validateText = (text: string) => {
    if (!text || text.trim() === '') {
      onErrorChange(t('forms.serviceNote.required'));
    } else if (text.length > MAXIMUM_500_CHARACTERS) {
      onErrorChange(t('forms.serviceNote.maxLength'));
    } else {
      onErrorChange('');
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onEditChange(newValue);
    setValue(newValue);
    validateText(newValue);
  };

  useEffect(() => {
    if (!focusingID) {
      setValue(note.text);
    }
  }, [focusingID]);

  return (
    <Form className={styles.container}>
      <Form.Item validateStatus={error ? 'error' : ''} help={error} className="pb-0">
        {focusingID === note.id ? (
          <Input
            value={value}
            onChange={handleTextChange}
            onBlur={(e) => {
              e.preventDefault();
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Escape') {
                setValue(note.text);
                onErrorChange('');
                onEscape();
              } else if (e.key === 'Enter') {
                !error && onEnter();
              }
            }}
          />
        ) : (
          <Text>{value}</Text>
        )}
      </Form.Item>
    </Form>
  );
};

export { ContentEditable };
