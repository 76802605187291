import { AsyncState } from 'hooks/useAsyncState';
import React, { ForwardedRef, Fragment, useImperativeHandle, useRef } from 'react';

type Props<T> = {
  infiniteScrollRef: ForwardedRef<unknown>;
  hasMore: boolean;
  dataSource: AsyncState<T[]>;
  endMessage?: React.ReactNode;
  skeleton?: React.ReactNode;
  children: (value: T) => React.ReactNode;
  loadMore: () => void;
};

const MAX_HEIGHT = 450;

const SKELETON_FULL_LIST_LENGTH = 4;

const InfiniteScroll = <T extends AnyObject>({
  infiniteScrollRef,
  hasMore,
  dataSource,
  endMessage,
  skeleton,
  children,
  loadMore
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const firstRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (scrollTop + clientHeight >= scrollHeight && hasMore) {
      loadMore();
    }
  };

  useImperativeHandle(infiniteScrollRef, () => ({
    onScrollTop: () => {
      firstRef.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }));

  const refreshListLoading = dataSource.loading && dataSource.data.length === 0;
  const loadMoreLoading = dataSource.loading && dataSource.data.length > 0;

  return (
    <div
      ref={containerRef}
      style={{
        height: MAX_HEIGHT
      }}
      className="overflow-y-auto custom-scrollbar"
      onScroll={handleScroll}
    >
      <div ref={firstRef} />
      {dataSource.data.map((value: T) => children(value))}
      <div className="d-flex justify-content-center">{!hasMore && endMessage}</div>
      {refreshListLoading && (
        <div className="d-flex flex-column gap-4">
          {Array(SKELETON_FULL_LIST_LENGTH)
            .fill(0)
            .map((_, index) => (
              <Fragment key={index}>{skeleton}</Fragment>
            ))}
        </div>
      )}
      {loadMoreLoading && <>{skeleton}</>}
    </div>
  );
};

export { InfiniteScroll };
