import { AccountPageEmptyIcon } from 'assets/icons';
import { EmptyPage, Pagination, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import useListAccountController from './ListAccountController';
import { useSearchParams } from 'react-router-dom';
import { TableListAccount } from '../components';
import { eossDocLinks } from 'constant';

export default function ListAccountPageView() {
  const { accountList, onPageChange } = useListAccountController();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const searchKey = searchParams.get('search');

  return (
    <>
      <Section
        title={t('accountPage.sections.listAccount.title')}
        docLink={eossDocLinks.customer.listing.listingTable}
      >
        <EmptyPage
          showEmpty={!searchKey}
          title={t('accountPage.emptyTable.title')}
          subTitle={t('accountPage.emptyTable.subTitle')}
          emptyIcon={<AccountPageEmptyIcon />}
        >
          <TableListAccount searchKey={searchKey} accountList={accountList} />
        </EmptyPage>
      </Section>
      <Pagination
        total={accountList.paging.total}
        pageSize={accountList.paging.pageLimit}
        initPageNumber={accountList.paging.pageNum}
        onChange={(page: number) => onPageChange(page)}
      />
    </>
  );
}
