import React, { useRef, useState } from 'react';
import type { TServiceNote } from 'models/ServiceNote';
import styles from './note.module.scss';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from 'constant';
import { Button, Skeleton, Tooltip } from 'antd';
import { DeleteIcon, EditIcon } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { useAppUtil } from 'context/UtilContext';
import { ContentEditable } from './ContentEditable';
import { PermissionWrapper } from 'presentation/components';
import { EAdminTypeDisplayName, EStorageKey, ESubFeatureKey } from 'enums';

const MIN_HEIGHT = 80;

type TEossUserStorage = {
  username: string;
  adminTypeDisplayName: string;
} | null;

const Note = ({
  focusingID,
  note,
  onEditNote,
  onDeleteNote,
  onChangeFocusingID
}: {
  focusingID?: number;
  note: TServiceNote;
  onEditNote: ({ id, text }: { id: number; text: string }) => Promise<void>;
  onDeleteNote: (id: number) => void;
  onChangeFocusingID: (id?: number) => void;
}) => {
  const { t } = useTranslation();

  const { openModal } = useAppUtil();

  const newData = useRef<string>(note.text);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const isNotEditByMe =
    (note.createdBy?.firstName ?? '') + (note.createdBy?.lastName ?? '') !==
    (note.modifiedBy?.firstName ?? '') + (note.modifiedBy?.lastName ?? '');

  const eossCurrentUserStorage = localStorage.getItem(EStorageKey.EOSS_CURRENT_USER);

  const currUser = (
    eossCurrentUserStorage ? JSON.parse(eossCurrentUserStorage) : null
  ) as TEossUserStorage;

  const isMineServiceNote =
    currUser && currUser?.username ? currUser.username === note?.createdBy?.username : false;

  const isOpstaffAccount = currUser
    ? currUser?.adminTypeDisplayName === EAdminTypeDisplayName.EYECAST_OPS
    : false;

  const handleDeleteNote = (id: number) => {
    onChangeFocusingID(undefined);
    openModal({
      title: t('components.confirmationTitle'),
      content: t('components.confirmationMessage', {
        action: t('components.delete').toLowerCase(),
        entity: t('serviceNote.entity').toLowerCase()
      }),
      okText: t('components.delete'),
      cancelText: t('components.cancel'),
      onOk: () => onDeleteNote(Number(id)),
      wrapClassName: styles.confirmModal
    });
  };

  const onEscapePress = () => {
    onChangeFocusingID(undefined);
  };

  const onEnterPress = () => {
    setLoading(true);
    onEditNote({
      id: note.id,
      text: newData.current.toString()
    }).finally(() => setLoading(false));
    onChangeFocusingID(undefined);
  };

  return (
    <div
      className={`${styles.note} w-100`}
      style={{
        minHeight: MIN_HEIGHT
      }}
    >
      <div className="flex-fill">
        <div className={styles.noteTitle}>
          <div className="fs-12 fw-normal">
            {note?.createdTime ? dayjs(note.createdTime).format(DATETIME_FORMAT) : ''}{' '}
            {(note.createdBy?.firstName || note.createdBy?.lastName) && (
              <>
                {'by '}
                <span className={styles.noteOwner}>{`${note.createdBy?.firstName ?? ''} ${
                  note.createdBy?.lastName ?? ''
                }`}</span>
              </>
            )}
          </div>
        </div>
        <Skeleton loading={loading} active paragraph={{ rows: 0 }}>
          <div>
            <ContentEditable
              focusingID={focusingID}
              note={note}
              error={error}
              onErrorChange={(value: string) => setError(value)}
              onEscape={onEscapePress}
              onEditChange={(value: string) => {
                newData.current = value;
              }}
              onEnter={() => {
                setLoading(true);
                if (note?.text.trim() !== newData?.current.trim()) {
                  onEditNote({
                    id: note.id,
                    text: newData.current?.trim().toString()
                  }).finally(() => setLoading(false));
                } else {
                  setLoading(false);
                }
                onChangeFocusingID(undefined);
              }}
            />
            {focusingID !== note.id &&
              (note?.modifiedTime || note.modifiedBy?.firstName || note.modifiedBy?.lastName) && (
                <span className="fs-12 fw-light align-self-center fst-italic color-grey">
                  {'edited '}
                  {note?.modifiedTime ? dayjs(note.modifiedTime).format(DATETIME_FORMAT) : ''}{' '}
                  {isNotEditByMe && (note.modifiedBy?.firstName || note.modifiedBy?.lastName) && (
                    <>
                      {'by '}
                      <span className={styles.noteOwner}>{`${note.modifiedBy?.firstName ?? ''} ${
                        note.modifiedBy?.lastName ?? ''
                      }`}</span>
                    </>
                  )}
                </span>
              )}
          </div>
        </Skeleton>
        {focusingID === note.id && !error && (
          <span className="fst-italic fs-12">
            Press escape to{' '}
            <span
              className="fw-medium cursor-pointer"
              style={{
                color: 'var(--primary-1)'
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onEscapePress();
              }}
            >
              cancel
            </span>{' '}
            • Press enter to{' '}
            <span
              className="fw-medium cursor-pointer"
              style={{
                color: 'var(--primary-1)'
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onEnterPress();
              }}
            >
              save
            </span>
          </span>
        )}
      </div>
      <div
        className="d-flex"
        style={{
          width: 60
        }}
      >
        {isOpstaffAccount && !isMineServiceNote ? (
          <></>
        ) : (
          <>
            <PermissionWrapper.Update subFeature={ESubFeatureKey.SERVICE_NOTE}>
              <Tooltip title="Edit Service Note" trigger="hover">
                <Button
                  icon={<EditIcon />}
                  type="text"
                  onClick={() => {
                    onChangeFocusingID(note.id);
                  }}
                />
              </Tooltip>
            </PermissionWrapper.Update>
            <PermissionWrapper.Delete subFeature={ESubFeatureKey.SERVICE_NOTE}>
              <Tooltip title="Delete Service Note" trigger="hover">
                <Button
                  icon={<DeleteIcon />}
                  type="text"
                  onClick={() => handleDeleteNote(note.id)}
                />
              </Tooltip>
            </PermissionWrapper.Delete>
          </>
        )}
      </div>
    </div>
  );
};

export { Note };
