import React from 'react';
import { Tooltip } from 'antd';
import { AiFillQuestionCircle } from 'react-icons/ai';

interface DocumentationTooltipProps {
  docLink: string;
  className: string | undefined;
}

const DocumentationTooltip: React.FC<DocumentationTooltipProps> = ({ docLink, className }) => (
  <Tooltip title={() => <span>Learn more in the documentation</span>} destroyTooltipOnHide>
    <AiFillQuestionCircle
      className={className}
      size={16}
      color="var(--primary-1)"
      onClick={() => window.open(docLink, '_blank')}
    />
  </Tooltip>
);

export default DocumentationTooltip;
