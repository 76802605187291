import React from 'react';
import { Tooltip } from 'antd';
import { AiFillQuestionCircle } from 'react-icons/ai';
import styles from './navigableTitle.module.scss';

const NavigableTitle: React.FC<{ navigateLink: string; title: string }> = ({
  navigateLink,
  title
}) => {
  return (
    <div className={styles.container}>
      {title}

      <Tooltip title={() => <span>Learn more in the documentation</span>} destroyTooltipOnHide>
        <AiFillQuestionCircle
          className={styles.icon}
          size={16}
          color="var(--primary-1)"
          onClick={() => {
            window.open(navigateLink, '_blank');
          }}
        />
      </Tooltip>
    </div>
  );
};

export { NavigableTitle };
