import { AsyncState } from 'hooks/useAsyncState';
import { TServiceNote } from 'models/ServiceNote';
import { InfiniteScroll, PermissionWrapper, Section } from 'presentation/components';
import React, { ChangeEvent, forwardRef, useMemo } from 'react';
import { Note } from './Note';
import { Button, Form, Input, Popover, Select, Skeleton } from 'antd';
import { CiFilter } from 'react-icons/ci';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { ERoleKey, ESortingServiceNotes, ESubFeatureKey } from 'enums';
import useResponsive from 'hooks/useResponsive';
import { DropdownIcon } from 'assets/icons';
import styles from './serviceNote.module.scss';
import ValidationUtilities from 'utils/validationUtils';

type Props = {
  infiniteScrollRef: React.ForwardedRef<unknown>;
  hasMore: boolean;
  focusingID?: number;
  searchServiceNoteValue: string;
  selectSortFilter: number;
  dataSource: AsyncState<TServiceNote[]>;
  topLoading: boolean;
  skeleton?: React.ReactNode;
  total: number;
  onLoadMore: () => void;
  onAddNote: (text: string) => Promise<void>;
  onEditNote: ({ id, text }: { id: number; text: string }) => Promise<void>;
  onDeleteNote: (id: number) => Promise<void>;
  onFocusingIDChange: (id?: number) => void;
  onSelectSortFilterChange: (value: number) => void;
  onServiceNoteSearchChange: (value: string) => void;
  onClickRefreshServiceNote: () => Promise<void>;
};

const INPUT_SEARCH_NOTE_WIDTH = 280;

const DROPDOWN_WIDTH_DESKTOP = 350;

const ServiceNote: React.FC<Props> = forwardRef(
  ({
    infiniteScrollRef,
    hasMore,
    focusingID,
    searchServiceNoteValue,
    dataSource,
    selectSortFilter,
    topLoading,
    skeleton,
    total,
    onLoadMore,
    onEditNote,
    onAddNote,
    onDeleteNote,
    onFocusingIDChange,
    onSelectSortFilterChange,
    onServiceNoteSearchChange,
    onClickRefreshServiceNote
  }) => {
    const { t } = useTranslation();
    const [addForm] = useForm();

    const { isMobile } = useResponsive();

    const menuItems = useMemo(
      () => [
        {
          label: t('cameraGroupPage.tabs.serviceNote.byDateAsc'),
          value: ESortingServiceNotes.ORDER_BY_DATE_ASC
        },
        {
          label: t('cameraGroupPage.tabs.serviceNote.byDateDesc'),
          value: ESortingServiceNotes.ORDER_BY_DATE_DESC
        }
      ],
      [t]
    );

    const handleAddNotes = (serviceNote: string) => {
      if (serviceNote.trim().length > 0) {
        onAddNote(serviceNote);
        addForm.resetFields();
      }
    };

    return (
      <PermissionWrapper roleKey={ERoleKey.VIEW} subFeature={ESubFeatureKey.SERVICE_NOTE}>
        <Section
          title={
            <div className="d-flex align-items-center gap-2">
              <span>{t('cameraGroupPage.tabs.serviceNote.serviceEntries')}</span>
              <Popover
                trigger="click"
                content={
                  <div className="p-3" onClick={(e) => e.stopPropagation()}>
                    <div className="fs-12">{t('cameraGroupPage.cameraBindingTable.sortBy')}</div>
                    <div
                      style={{
                        width: 360
                      }}
                    >
                      <Select
                        className="w-100"
                        optionFilterProp="children"
                        defaultActiveFirstOption
                        options={menuItems}
                        value={selectSortFilter}
                        dropdownStyle={{
                          ...(isMobile ? {} : { width: DROPDOWN_WIDTH_DESKTOP })
                        }}
                        suffixIcon={<DropdownIcon />}
                        onChange={(value: number) => {
                          onSelectSortFilterChange && onSelectSortFilterChange(value);
                        }}
                      />
                    </div>
                  </div>
                }
                placement="right"
              >
                <Button
                  size="small"
                  icon={<CiFilter color="var(--persian-blue)" />}
                  shape="circle"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Popover>
            </div>
          }
          extraControl={
            <div className="d-flex gap-2 py-1">
              <Input
                placeholder={t('cameraGroupPage.tabs.serviceNote.placeholder.search')}
                allowClear
                style={{
                  width: INPUT_SEARCH_NOTE_WIDTH
                }}
                value={searchServiceNoteValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onServiceNoteSearchChange(e.target.value);
                }}
              />
            </div>
          }
          onClickRefresh={onClickRefreshServiceNote}
          classNameBody={styles.container}
          className="mb-0"
        >
          <Form
            form={addForm}
            onFinish={({ serviceNote }: { serviceNote: string }) => handleAddNotes(serviceNote)}
            className="d-flex mb-4"
          >
            <Form.Item
              name="serviceNote"
              className="flex-fill"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.serviceNoteTextValidationFn({
                      value,
                      errorMessage: t('forms.serviceNote.maxLength')
                    })
                }
              ]}
            >
              <Input
                className="border-right-radius-0"
                allowClear
                placeholder={t('cameraGroupPage.tabs.serviceNote.placeholder.add')}
              />
            </Form.Item>
            <PermissionWrapper
              roleKey={ERoleKey.UPDATE}
              subFeature={ESubFeatureKey.SERVICE_NOTE}
              config={{
                displayType: 'disabled'
              }}
            >
              <Button className="btn-fill btn-action-section-suffix" htmlType="submit">
                {t('components.add')}
              </Button>
            </PermissionWrapper>
          </Form>
          {topLoading && skeleton}
          <InfiniteScroll<TServiceNote>
            infiniteScrollRef={infiniteScrollRef}
            dataSource={dataSource}
            loadMore={onLoadMore}
            hasMore={hasMore}
            skeleton={<Skeleton loading={true} active paragraph={{ rows: 2 }} />}
            endMessage={<div className="fs-14">No more items</div>}
          >
            {(note: TServiceNote) => (
              <Note
                key={note.id}
                note={note}
                onEditNote={onEditNote}
                onDeleteNote={onDeleteNote}
                focusingID={focusingID}
                onChangeFocusingID={onFocusingIDChange}
              />
            )}
          </InfiniteScroll>
          <div className="d-flex justify-content-end mt-2 me-2">
            {dataSource.data.length}/{total} note{dataSource.data.length > 1 ? 's' : ''}
          </div>
        </Section>
      </PermissionWrapper>
    );
  }
);

export { ServiceNote };
