import {
  EMAIL_REGEX,
  USERNAME_VALIDATION_REGEX,
  MAXIMUM_4_CHARACTERS,
  MAXIMUM_16_CHARACTERS,
  MAXIMUM_32_CHARACTERS,
  MAXIMUM_64_CHARACTERS,
  MAXIMUM_128_CHARACTERS,
  MAXIMUM_256_CHARACTERS,
  COMMON_NAME_REGEX,
  CAMERA_GROUP_NAME_REGEX,
  MAXIMUM_8_CHARACTERS,
  LAT_REGEX,
  LONG_REGEX,
  MAXIMUM_500_CHARACTERS
} from 'constant';

type ErrorMessageKeys = 'maxLength' | 'pattern';

interface TValidation {
  value: string;
  errorMessage?: string;
  errorMessages?: Map<ErrorMessageKeys, string>;
}

interface TPasswordValidation extends TValidation {
  editMode?: boolean;
  passwordDependency: string;
}

export default {
  /**
   * Validates the ***login*** field in the General Form (Admin Page) and the ***username*** field in the General Form (Eyeview User Page).
   *
   * @param {TValidation} params - The parameters for the validation.
   * @param {string} params.value - The value to validate.
   * @param {string | undefined} params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is between 4 and 32 characters. If not, the promise is rejected with the provided error message.
   * - Checks if the value matches the username validation regex. If not, the promise is rejected with the provided error message.
   */
  loginValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }
    if (
      value &&
      errorMessage &&
      value.trim().length > 0 &&
      (value.trim().length < MAXIMUM_4_CHARACTERS || value.trim().length > MAXIMUM_32_CHARACTERS)
    ) {
      return Promise.reject(errorMessage);
    }

    if (value && errorMessage && !USERNAME_VALIDATION_REGEX.test(value.trim())) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***confirm password*** field in the General Form (Admin Page) and the General Form (Eyeview User Page).
   *
   * @param {TPasswordValidation} params - The parameters for the validation.
   * @param {string} params.value - The value to validate.
   * @param {boolean} [params.editMode] - Indicates whether the form is in edit mode.
   * @param {string} params.passwordDependency - The password value to compare with.
   * @param {string} [params.errorMessage] - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes, or rejects with the error message if it fails.
   * @remarks
   * This functions performs validation on the confirm password must be equal to the password dependency
   */
  confirmPasswordValidationFn: ({
    value,
    editMode,
    passwordDependency,
    errorMessage
  }: TPasswordValidation): Promise<void> => {
    // BOTH PASSWORD AND CONFIRM PASSWORD ARE EMPTY
    if (!editMode && (!value || value?.trim().length === 0)) {
      return Promise.resolve();
    }

    // COMPARE PASSWORD AND CONFIRM PASSWORD
    if (errorMessage && (passwordDependency ?? '')?.trim() !== (value ?? '')?.trim()) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***first name*** field or ***last name*** field in the General Form (Admin Page), ***name*** field in the Contact Form (Customer Page) and the General Form (Eyeview User Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 64 characters. If so, the promise is rejected with the provided error message.
   */
  shortNameValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_64_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***email*** field in the General Form (Admin Page), ***email*** field in the Contact Form (Customer Page) and the General Form (Eyeview User Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessages - A map of error message keys to their corresponding messages.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the corresponding error message.
   *
   * @description
   * This regex allows for email addresses with any combination of alphanumeric characters, dots, hyphens, and underscores in the username and domain name parts, as long as they are followed by a valid top-level domain. It also allows for leading and trailing whitespace characters.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 64 characters. If so, the promise is rejected with the 'maxLength' error message.
   * - Checks if the value matches the email validation **Regex**. If not, the promise is rejected with the 'pattern' error message.
   * - Regex:
   *
   *    - ***^\s****: Matches any leading whitespace characters
   *    - ***([^\s@]+@[^\s@]+\.[^\s@]+)***: Matches the email address itself. Matches the email address itself. It consists of three parts separated by '@' and '.'.
   *
   *        - ***[^\s@]+***: Matches one or more characters that are not whitespace or '@'. This represents the username part of the email.
   *        - ***@[^\s@]+***: Matches '@' followed by one or more characters that are not whitespace or '@'. This represents the domain name part of the email.
   *        - ***\.[^\s@]+***: Matches '.' followed by one or more characters that are not whitespace or '@'. This represents the top-level domain (TLD) part of the email.
   *    - ***\s*$***: Matches any trailing whitespace characters.
   */
  emailValidationFn: ({ value, errorMessages }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessages && value.trim().length > MAXIMUM_64_CHARACTERS) {
      return Promise.reject(errorMessages.get('maxLength'));
    }

    if (value && errorMessages && !EMAIL_REGEX.test(value.trim())) {
      return Promise.reject(errorMessages.get('pattern'));
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***phone number*** field in the General Form (Admin Page), ***phone*** field in the Contact Form (Customer Page) and the General Form (Eyeview User Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 32 characters. If so, the promise is rejected with the provided error message.
   */
  phoneNumberValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_32_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***street*** field in the General Form (Admin Page), General Form (Customer Page) and the General Form (Eyeview User Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 128 characters. If so, the promise is rejected with the provided error message.
   */
  streetValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_128_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***city*** field in the General Form (Admin Page), General Form (Customer Page) and the General Form (Eyeview User Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 64 characters. If so, the promise is rejected with the provided error message.
   */
  cityValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_64_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***state*** field in the General Form (Admin Page), General Form (Customer Page) and the General Form (Eyeview User Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 32 characters. If so, the promise is rejected with the provided error message.
   */
  stateValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_32_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***zip*** field in the General Form (Admin Page), General Form (Customer Page) and the General Form (Eyeview User Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 16 characters. If so, the promise is rejected with the provided error message.
   */
  zipValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_16_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***comment*** field in the General Form (Admin Page, Customer Page, Device Group Page and Eyeview User Page) and Form Device Information (Device Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 256 characters. If so, the promise is rejected with the provided error message.
   */
  commentValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_256_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***Customer Name*** field in the General Form (Customer Page) or ***Domain Name*** field in the General Form (Domain Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is less than 4 characters or greater than 32 characters. If so, the promise is rejected with the provided error message.
   * - Checks if the value matches the **Regex**. If not, the promise is rejected with the provided error message.
   * - Regex: Regular expression for validating a common name. The regex pattern ***^[a-zA-Z0-9\-_]{4,32}$*** is used to match a string that consists of lowercase letters, numbers, underscores, and hyphens between 4 and 32 characters long.
   */
  fullNameValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (
      value &&
      errorMessage &&
      (value.trim().length < MAXIMUM_4_CHARACTERS || value.trim().length > MAXIMUM_32_CHARACTERS)
    ) {
      return Promise.reject(errorMessage);
    }

    if (value && errorMessage && !COMMON_NAME_REGEX.test(value.trim())) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***url*** field in the General Form (Customer Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 64 characters. If so, the promise is rejected with the provided error message.
   */
  urlValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_64_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***deviceName*** field in the Form Device Information (Device Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is less than 4 characters or greater than 128 characters. If so, the promise is rejected with the provided error message.
   */
  deviceNameValidationFn: ({ value, errorMessage }: TValidation) => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_128_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***Form Factor*** field or ***Lens*** field in the Form Device Information (Device Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 32 characters. If so, the promise is rejected with the provided error message.
   */
  formFactorAndLensValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_32_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***location*** field in the Form Device Information (Device Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 256 characters. If so, the promise is rejected with the provided error message.
   */
  locationValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_256_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***Device Group Name*** field in the General Form (Device Group Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is less than 8 characters or greater than 64 characters. If so, the promise is rejected with the provided error message.
   * - Checks if the value matches the **Regex**. If not, the promise is rejected with the provided error message.
   * - Regex:
   *
   *    - ***^*** and ***$***: These are start and end anchors, respectively. They ensure that the entire string matches the pattern, not just a part of it.
   *    - ***[a-zA-Z0-9-_: ]***: This character class matches any lowercase letter (a-z), uppercase letter (A-Z), digit (0-9), hyphen (-), underscore (_), or space.
   *    - ***{8,64}***: This quantifier specifies that the previous character class (a-zA-Z0-9-_: ) should be matched between 8 and 64 length.
   */
  deviceGroupNameValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (
      value &&
      errorMessage &&
      (value.trim().length < MAXIMUM_8_CHARACTERS || value.trim().length > MAXIMUM_64_CHARACTERS)
    ) {
      return Promise.reject(errorMessage);
    }

    if (!CAMERA_GROUP_NAME_REGEX.test(value)) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***address*** field in the General Form (Device Group Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes. If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the value is empty or only contains whitespace characters. If so, the promise is resolved.
   * - Checks if the value length is greater than 256 characters. If so, the promise is rejected with the provided error message.
   */
  addressValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_256_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***latitude*** field in the General Form (Device Group Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The latitude value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes.
   * If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the latitude value is empty or only contains whitespace characters.
   * If so, the promise is resolved.
   * - Checks if the latitude value is a valid latitude according to the specified **Regex**.
   * If not, the promise is rejected with the provided error message.
   *
   * - Regex:
   *
   *    - ***^*** and ***$*** are start and end anchors, respectively. They ensure that the entire string matches the pattern.
   *    - ***\(?*** matches an optional opening parenthesis.
   *    - ***[+-]?*** matches an optional plus or minus sign.
   *    - ***(90(\.0+)?|[1-8]?\d(\.\d+)?)*** is a group that matches either:
   *        - ***90(\.0+)?***: The number 90 followed by an optional decimal point and one or more zeros.
   *        - ***[1-8]?\d(\.\d+)?***: A number between 1 and 8 followed by any digit, and an optional decimal point and one or more digits.
   *    - ***\)?*** matches an optional closing parenthesis.
   */
  latitudeValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (
      value &&
      errorMessage &&
      value.toString().length > 0 &&
      !LAT_REGEX.test(value.toString().trim())
    ) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***longitude*** field in the General Form (Device Group Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The longitude value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes.
   * If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the longitude value is empty or only contains whitespace characters.
   * If so, the promise is resolved.
   * - Checks if the longitude value is a valid longitude according to the specified **Regex**.
   * If not, the promise is rejected with the provided error message.
   *
   * - Regex:
   *
   *    - ***^\s?***: Matches the start of the string followed by optional white spaces.
   *    - ***[+-]?***: Matches an optional plus (+) or minus (-) sign.
   *    - ***(180(\.0+)?|1[0-7]\d(\.\d+)?|\d{1,2}(\.\d+)?)***: Matches the longitude value. It checks for three different cases:
   *    - ***180(\.0+)?***: Matches 180 followed by optional decimal points and zeros.
   *    - ***1[0-7]\d(\.\d+)?***: Matches numbers between 100 and 179 followed by optional decimal points and digits.
   *    - ***\d{1,2}(\.\d+)?***: Matches numbers between 0 and 99 followed by optional decimal points and digits.
   *    - ***\)?$***: Matches an optional closing parenthesis (") at the end of the string.
   */
  longitudeValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (
      value &&
      errorMessage &&
      value.toString().length > 0 &&
      !LONG_REGEX.test(value.toString().trim())
    ) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  /**
   * Validates the ***tax*** field and ***discount*** field in the Billing Form (Device Group Page).
   *
   * @param params - The parameters for the validation.
   * @param params.value - The value to validate.
   * @param params.errorMessage - The error message to return if validation fails.
   *
   * @returns {Promise<void>} A promise that resolves if the validation passes.
   * If the validation fails, the promise will be rejected with the error message.
   *
   * @remarks
   * This function performs the following validations:
   * - Checks if the `value` is empty or only contains whitespace characters.
   * If so, the promise is resolved.
   * - Checks if the `value` length is greater than 32 characters.
   * If so, the promise is rejected with the provided error message.
   */
  taxAndDiscountValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_32_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  },
  serviceNoteTextValidationFn: ({ value, errorMessage }: TValidation): Promise<void> => {
    if (!value || value?.trim().length === 0) {
      return Promise.resolve();
    }

    if (value && errorMessage && value.trim().length > MAXIMUM_500_CHARACTERS) {
      return Promise.reject(errorMessage);
    }

    return Promise.resolve();
  }
};
