import { Button, Form, Input } from 'antd';
import { AddIconNoOutline } from 'assets/icons';
import { Routes } from 'enums';
import { LeftColumn, PermissionWrapper, RightColumn, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useDomainPageController } from './DomainPageController';
import { useEffect, useRef } from 'react';
import useFlag from 'hooks/useFlag';
import { useLoaderContext } from 'context/LoaderContext';
import { eossDocLinks } from 'constant';

type FieldType = {
  searchText: string;
};

const DomainPageView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { loader } = useLoaderContext();

  const { form, refetchCountRef, handleResetSearch, handleSearch } = useDomainPageController();
  // const isShowCreateAction = useMatch(Routes.EDomainRoutes.LISTING);
  // 2024-02-09 Feedback: Create Action should be shown in both Listing and Detail page
  const isInListingPage = useMatch(Routes.EDomainRoutes.LISTING);
  const isInDetailPage = useMatch(Routes.EDomainRoutes.DETAILED);
  const canShowCreateAction =
    isInListingPage || (isInDetailPage && isInDetailPage.pathname != Routes.EDomainRoutes.CREATE); // check create because useMatch understands /domain/create as /domain/:domainId

  const [leftColumnOpen, markAsLeftColumnOpen, markAsLeftColumnClose] = useFlag(true);

  const toolTipRef = useRef<{
    onHideTooltip: () => void;
    onShowTooltip: () => void;
  }>();

  useEffect(() => {
    return () => {
      markAsLeftColumnOpen();
    };
  }, []);

  return (
    <div className="wrap-container h-100 overflow-hidden">
      <div className="h-100 w-100 position-relative">
        <LeftColumn
          withCollapse
          leftColumnOpen={leftColumnOpen}
          onMouseEnter={() => toolTipRef.current?.onShowTooltip()}
          onMouseMove={() => toolTipRef.current?.onShowTooltip()}
          onMouseLeave={() => toolTipRef.current?.onHideTooltip()}
        >
          <Section
            title={t('domainPage.sections.search.title')}
            docLink={eossDocLinks.domain.listing.searchDomain}
            classNameBody="p-4"
          >
            <Form
              id="domainPage_searchAction"
              form={form}
              layout="vertical"
              initialValues={{ customerId: null, groupName: '*' }}
              onFinish={(values) => {
                handleSearch(values.searchText);
              }}
              onFieldsChange={(changedFields) => {
                const searchTextField = changedFields.find(
                  (field) => field?.name?.includes('searchText')
                );

                if (searchTextField && !searchTextField.value) {
                  handleResetSearch();
                }
              }}
            >
              <Form.Item<FieldType> name={'searchText'} className="mb-2">
                <Input id="input-search-domain" type="search" className="search-input" allowClear />
              </Form.Item>

              <div className="fs-12 fw-normal text-black mt-2 mb-3">
                {t('domainPage.sections.search.instruction')}
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  id="btn-search-domain"
                  htmlType="submit"
                  className="btn-action btn-fill"
                  onClick={() => {
                    const event = new CustomEvent('close-sidebar-action');
                    document.dispatchEvent(event);
                  }}
                >
                  <span className="fs-14 fw-semibold">{t('components.search')}</span>
                </Button>
              </div>
            </Form>
          </Section>

          {canShowCreateAction && (
            <PermissionWrapper.Create>
              <Section
                title={t('domainPage.sections.create.title')}
                docLink={eossDocLinks.domain.create}
                classNameBody="px-4 py-5"
              >
                <Button
                  id="domainPage_createAction"
                  onClick={() => {
                    loader.start();
                    navigate(Routes.EDomainRoutes.CREATE);
                  }}
                  className="btn-create w-100 d-flex justify-content-center align-items-center cursor-pointer"
                >
                  <div className="h-100 d-flex align-items-center">
                    <AddIconNoOutline color="var(--primary-1)" />
                  </div>
                  <div className="btn-create-content d-flex align-items-center fs-14 fw-semibold ms-2 h-100">
                    {t('components.create')}
                  </div>
                </Button>
              </Section>
            </PermissionWrapper.Create>
          )}
        </LeftColumn>
        <RightColumn
          toolTipRef={toolTipRef}
          leftColumnOpen={leftColumnOpen}
          onSideBarClick={() => {
            leftColumnOpen ? markAsLeftColumnClose() : markAsLeftColumnOpen();
          }}
        >
          <Outlet context={{ refetchCount: refetchCountRef.current }} />
        </RightColumn>
      </div>
    </div>
  );
};

export default DomainPageView;
