import { Button, Collapse, Tooltip } from 'antd';
import React, { forwardRef, LegacyRef } from 'react';
import styles from './section.module.scss';
import { ExpandIcon } from 'assets/icons';
import { RefreshIcon } from 'assets/icons';
import useResponsive from 'hooks/useResponsive';
import { t } from 'i18next';
import DocumentationTooltip from '../DocumentationTooltip/DocumentationTooltip';

type Props = {
  headerId?: string;
  contentId?: string;
  footerId?: string;
  bodyRef?: LegacyRef<HTMLDivElement>;
  title?: React.ReactNode;
  docLink?: string;
  children: React.ReactNode;
  classNameBody?: string;
  className?: string;
  headerStyle?: React.CSSProperties;
  allowCollapse?: boolean;
  warningMessage?: string;
  keyPanel?: number | string;
  expandedByDefault?: boolean; // Requires keyPanel
  extraControl?: React.ReactNode | JSX.Element;
  extraFooter?: React.ReactNode | JSX.Element;
  onClickRefresh?: () => void;
};

export const Section = forwardRef(
  (
    {
      headerId,
      contentId,
      footerId,
      bodyRef,
      title,
      docLink,
      children,
      classNameBody = '',
      className = '',
      allowCollapse,
      warningMessage,
      headerStyle = {},
      keyPanel,
      expandedByDefault,
      extraControl,
      extraFooter,
      onClickRefresh
    }: Props,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => {
    const { isMobile } = useResponsive();
    if (allowCollapse) {
      return (
        <div ref={ref} className={`${styles.container} rounded overflow-hidden ${className}`}>
          <Collapse
            defaultActiveKey={expandedByDefault ? keyPanel : undefined}
            expandIconPosition="end"
            bordered={false}
            className={styles.collapse}
            style={{ background: 'var(--primary-4)' }}
            expandIcon={(panelProps) => {
              return <ExpandIcon expanded={panelProps.isActive || false} />;
            }}
            items={[
              {
                forceRender: true,
                key: keyPanel,
                label: (
                  <div
                    id={headerId}
                    className={`${styles.header} d-flex align-items-center justify-content-between`}
                  >
                    <div className="d-flex align-items-center" style={headerStyle}>
                      <div className={styles.titleContainer}>
                        <div className={styles.title}>{title}</div>

                        {docLink && (
                          <DocumentationTooltip docLink={docLink} className={styles.icon} />
                        )}
                      </div>
                      {warningMessage && (
                        <div
                          className="fs-12 rounded-4 px-2"
                          style={{ backgroundColor: 'var(--sub-1)', color: 'var(--sub-3)' }}
                        >
                          <span className="fw-medium ms-2">Warning: </span>
                          <span>{warningMessage}</span>
                        </div>
                      )}
                    </div>
                    {extraControl && <div>{extraControl}</div>}
                    {onClickRefresh && (
                      <Button className={styles.btnRefresh} onClick={() => onClickRefresh()}>
                        <div>
                          <RefreshIcon />
                        </div>
                      </Button>
                    )}
                  </div>
                ),
                children: (
                  <div id={contentId} ref={bodyRef} className={`${classNameBody} ${styles.body}`}>
                    {children}
                  </div>
                )
              }
            ]}
          />
        </div>
      );
    }
    return (
      <div
        id={headerId}
        ref={ref}
        className={`${styles.container} rounded overflow-hidden ${className}`}
      >
        {title && (
          <div
            className={`${styles.header} d-flex align-items-center rounded-top justify-content-between`}
            style={headerStyle}
          >
            <div className="d-flex align-items-center">
              <div className={styles.titleContainer}>
                <div className={styles.title}>{title}</div>
                {docLink && <DocumentationTooltip docLink={docLink} className={styles.icon} />}
              </div>
              {warningMessage && (
                <div
                  className="fs-12 rounded-4 px-2"
                  style={{ backgroundColor: 'var(--sub-1)', color: 'var(--sub-3)' }}
                >
                  <span className="fw-medium">Warning: </span>
                  <span>{warningMessage}</span>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              {extraControl && <div className="me-4">{extraControl}</div>}
              {onClickRefresh && (
                <div
                  className={`${styles.btnRefreshContainer} d-flex align-items-center justify-content-center`}
                >
                  <Tooltip
                    trigger={isMobile ? ['click'] : ['hover']}
                    destroyTooltipOnHide
                    placement="topLeft"
                    title={t('tooltip.refresh')}
                  >
                    <Button className={styles.btnRefresh} onClick={() => onClickRefresh()}>
                      <div>
                        <RefreshIcon />
                      </div>
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        )}
        <div id={contentId} ref={bodyRef} className={`${classNameBody} ${styles.body}`}>
          {children}
        </div>
        {extraFooter && (
          <div id={footerId} className={styles.footer}>
            {extraFooter}
          </div>
        )}
      </div>
    );
  }
);
