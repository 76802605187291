import { Form } from 'antd';
import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';
import { useLoaderContext } from 'context/LoaderContext';
import { useAppUtil } from 'context/UtilContext';
import { ECustomerType, EDeviceCommand, EHttpStatusCode, EStorageKey, Routes } from 'enums';
import { useEffectOnce } from 'hooks/useEffectOnce';
import {
  Content,
  DataList,
  type TCameraGroupAssign,
  type TCameraGroupAvailable,
  type TAccount,
  type TDevice,
  type TDeviceType,
  type TPaginationEOSS,
  type TSortOrder
} from 'models';
import {
  ApiErrorResponse,
  cameraBindingNotAllowed,
  invalidTypeError,
  multipleResourcesFound,
  replaceDeviceItselfError,
  resourceNotFound
} from 'models/ApiError';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { DeviceRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError, isApiErrorResponse, isNotDeviceCamera } from 'utils/common';
import useFlag from '../../../../hooks/useFlag';

export const useDevicesListingPageController = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { loader, isActive } = useLoaderContext();

  const { openNotification, destroy } = useAppUtil();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get('search');
  const sortKey = searchParams.get('sortKey') ?? '';
  const page = searchParams.get('page');
  const order = searchParams.get('order') as TSortOrder;
  const deviceRepository = DeviceRepository(AxiosClient);
  const { deviceId } = useParams<{ deviceId: string }>();
  const [searchForm] = Form.useForm();
  const [replaceForm] = Form.useForm();
  const currentDeviceType = useLoaderData() as TDeviceType;
  const { onSetCurrentDeviceType, leftColumnOpen, markAsLeftColumnOpen, markAsLeftColumnClose } =
    useOutletContext<{
      onSetCurrentDeviceType: (deviceType: TDeviceType | null) => void;
      leftColumnOpen: boolean;
      markAsLeftColumnOpen: () => void;
      markAsLeftColumnClose: () => void;
    }>();

  const [currentDevice, setCurrentDevice] = useState<TDevice | null>(null);
  const [listAccountsSU, setListAccountsSU] = useState<TAccount[]>([]);
  const [listAccountsValid, setListAccountsValid] = useState<TAccount[]>([]);
  const [listAccountsService, setListAccountsService] = useState<TAccount[]>([]);
  const [cameraGroupIdSelected, setCameraGroupIdSelected] = useState<number | null>(null);
  const [accountUnassignSelected, setAccountUnassignSelected] = useState<number | null>(null);
  const [accountUnassignSelectedModal, setAccountUnassignSelectedModal] = useState<number | null>(
    null
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);

  const [openReplaceDeviceModal, markAsReplaceDeviceModalOpen, markAsReplaceDeviceModalClose] =
    useFlag(false);

  // This list used for astro & ups binding, we need to filter out location bound (bind 1 group to 1 device ups or astro)
  const listAvailableOrigin = useRef<TCameraGroupAssign[]>([]);

  const [intervalActionTrigger, setIntervalActionTrigger] = useState<{
    payload: number[];
    value: boolean;
  }>({
    payload: [],
    value: false
  });

  const [bindGroupAction, setBindGroupAction] = useState<{
    isProcessing: boolean;
    isSuccess: boolean;
    isLoading: boolean;
    payload?: AnyObject;
  }>({ isProcessing: false, isSuccess: false, isLoading: false });
  const [unAssignAction, setUnAssignAction] = useState<{
    isProcessing: boolean;
    isSuccess: boolean;
  }>({ isProcessing: false, isSuccess: false });
  const [replaceAction, setReplaceAction] = useState<{
    isProcessing: boolean;
    isDeviceChecking: boolean;
    isSuccess: boolean;
    isLoading: boolean;
    payload?: TDevice;
  }>({
    isProcessing: false,
    isDeviceChecking: false,
    isSuccess: false,
    isLoading: false
  });
  const [rebootAction, setRebootAction] = useState<{
    isProcessing: boolean;
    isSuccess: boolean;
  }>({ isProcessing: false, isSuccess: false });

  const [listDevices, setListDevices] = useState<{
    data: TDevice[];
    isLoading: boolean;
    paging: TPaginationEOSS;
  }>({
    data: [],
    isLoading: false,
    paging: {
      pageLimit: DEFAULT_LIMIT,
      pageNum: DEFAULT_PAGE_NUMBER,
      total: 0,
      totalPage: 0
    }
  });

  const [listAvailableCameraGroupsBinding, setListAvailableCameraGroupsBinding] = useState<
    DataList<Array<TCameraGroupAssign>> & {
      pagination: TPaginationEOSS;
    }
  >({
    loading: false,
    data: [],
    pagination: {
      total: 0,
      pageNum: DEFAULT_PAGE_NUMBER - 1,
      pageLimit: DEFAULT_LIMIT,
      totalPage: 0
    }
  });

  const { t } = useTranslation();

  function handleResetSearch() {
    searchParams.delete('search');
    searchParams.delete('sortKey');
    searchParams.delete('order');
    searchParams.delete('page');
    setSearchParams(searchParams);
  }

  async function fetchListAccount({ accountIdFilter }: { accountIdFilter: number }) {
    try {
      const res = await deviceRepository.getListAccounts();
      setListAccountsValid(res);
      const listAccountSUData = res.filter(
        (account) =>
          account.accountType.name === ECustomerType.UNASSIGNED_DEVICES_CUSTOMER &&
          accountIdFilter !== account.id
      );
      const listAccountServiceData = res.filter(
        (account) => account.accountType.name !== ECustomerType.UNASSIGNED_DEVICES_CUSTOMER
      );

      setAccountUnassignSelected(listAccountSUData[0]?.id);
      setListAccountsSU(listAccountSUData);
      setListAccountsService(listAccountServiceData);
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('accountPage.entity')}`,
        description: message
      });
    }
  }

  async function handleFetchList({
    search,
    deviceTypeName,
    pageNumber
  }: {
    search: string;
    deviceTypeName: string;
    pageNumber: number;
    sortKey?: string;
    order?: TSortOrder;
  }) {
    if (listDevices.isLoading && listDevices.data.length > 0) return;
    setListDevices((prev) => {
      return {
        ...prev,
        isLoading: true
      };
    });
    try {
      const { data, code, page } = await deviceRepository.getListDevices({
        search,
        deviceTypeName,
        page: pageNumber,
        sortKey,
        order
      });
      if (code === EHttpStatusCode.OK) {
        if (page?.total === 1 && data.length > 0) {
          navigate(`${data[0].id}${searchText ? `?search=${searchText}` : ''}`, { replace: true });
          setListDevices((prev) => {
            return {
              ...prev,
              isLoading: false
            };
          });
          return;
        }
        setListDevices({
          data,
          isLoading: false,
          paging: page
            ? page
            : {
                pageLimit: DEFAULT_LIMIT,
                pageNum: DEFAULT_PAGE_NUMBER,
                total: data.length,
                totalPage: Math.ceil(data.length / DEFAULT_LIMIT)
              }
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('devicePage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('devicePage.entity')}`,
        description: message
      });
    }
  }

  const handleFetchAllCameraGroupsList = async ({ deviceId }: { deviceId: number }) => {
    try {
      if (listAvailableCameraGroupsBinding.loading) return;
      setCameraGroupIdSelected(null);
      setListAvailableCameraGroupsBinding((prev) => ({
        ...prev,
        loading: true,
        data: []
      }));
      const { data: dataFirstPage, code } = await deviceRepository.getListCameraGroupByDeviceId({
        deviceId,
        search: '*',
        page: DEFAULT_PAGE_NUMBER - 1
      });

      setListAvailableCameraGroupsBinding((prev) => {
        return {
          ...prev,
          data: [...dataFirstPage.content]
        };
      });

      const totalPage = dataFirstPage.total_pages;

      const isNotCameraDevice = isNotDeviceCamera(currentDevice);

      if (code === EHttpStatusCode.OK && currentDevice) {
        if (totalPage > 1) {
          const data = await Promise.allSettled(
            Array.from(Array(totalPage))
              .slice(1)
              .map(async (_, pageNumber) => {
                const { data, code } = await deviceRepository.getListCameraGroupByDeviceId({
                  search: '*',
                  deviceId,
                  page: pageNumber + 1 // Not magic number: We need to fetch from second page
                });
                if (code === EHttpStatusCode.OK) {
                  return data;
                }
              })
          );
          const dataSecondToEnd = data.reduce(
            (
              acc: Content[],
              currentValue: PromiseSettledResult<TCameraGroupAvailable | undefined>
            ) => {
              if (currentValue && currentValue.status === 'fulfilled') {
                if (currentValue?.value) {
                  const temp = [...acc, ...currentValue.value.content];
                  return temp;
                }
              }
              return acc;
            },
            []
          );

          let bindingAvailableList = [...dataFirstPage.content, ...dataSecondToEnd];

          // Need to filter out location because device group available is not filter out device group bound (only support device camera)
          if (isNotCameraDevice) {
            bindingAvailableList = [...dataFirstPage.content, ...dataSecondToEnd].filter(
              (value) => value.name !== currentDevice.location
            );
          }

          setListAvailableCameraGroupsBinding((prev) => ({
            ...prev,
            data: bindingAvailableList
          }));
          listAvailableOrigin.current = [...dataFirstPage.content, ...dataSecondToEnd];
        } else {
          let bindingAvailableList = [...dataFirstPage.content];
          // Need to filter out location because device group available is not filter out device group bound (only support device camera)
          if (isNotCameraDevice) {
            bindingAvailableList = [...dataFirstPage.content].filter(
              (value) => value.name !== currentDevice.location
            );
          }

          setListAvailableCameraGroupsBinding((prev) => ({
            ...prev,
            data: bindingAvailableList
          }));
          listAvailableOrigin.current = [...dataFirstPage.content];
        }
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('devicePage.sections.bindCameraGroup.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('devicePage.sections.bindCameraGroup.entity')}`,
        description: message
      });
    } finally {
      setListAvailableCameraGroupsBinding((prev) => {
        return {
          ...prev,
          loading: false
        };
      });
    }
  };

  const handleBindCameraGroup = async () => {
    if (!currentDevice || !cameraGroupIdSelected) return;
    const isNotCameraDevice = isNotDeviceCamera(currentDevice);
    try {
      setBindGroupAction((prev) => ({ ...prev, isLoading: true, isProcessing: true }));
      const deviceGroupName = listAvailableCameraGroupsBinding.data.find(
        (value: TCameraGroupAssign) => value.id === cameraGroupIdSelected
      )?.name;
      if (!deviceGroupName) {
        throw new Error(cameraBindingNotAllowed.message);
      }

      // If this device is camera device, execute binding action.
      // If not, update the device basic
      const res = await (isNotCameraDevice
        ? deviceRepository.updateDeviceBasic({
            deviceId: currentDevice?.id,
            comment: currentDevice.comment,
            form: currentDevice.formFactor ?? '',
            location: deviceGroupName
          })
        : deviceRepository.bindGroupToDevice({
            groupId: cameraGroupIdSelected,
            serialNumbers: currentDevice?.serialNumber
          }));

      if (res === EHttpStatusCode.OK) {
        setBindGroupAction({
          isLoading: false,
          isSuccess: true,
          isProcessing: false,
          payload: isNotCameraDevice ? { deviceGroupName } : undefined
        });
        sessionStorage.setItem(
          EStorageKey.DEVICE_GROUPS_BINDING_KEYS,
          JSON.stringify([cameraGroupIdSelected])
        );

        openNotification({
          type: 'success',
          title: `${t('actions.bind')} ${t('devicePage.sections.bindCameraGroup.entity')}`,
          description: `${t('components.success')}`,
          onClose: () => {
            sessionStorage.removeItem(EStorageKey.DEVICE_GROUPS_BINDING_KEYS);
            // Remove blinking animation by remove new-group className
            const elements = document.querySelectorAll(
              '#devicePage_deviceGroupBindingTable tbody tr.new-group'
            );
            if (elements.length > 0) {
              elements.forEach((element) => {
                element.classList.remove('new-group');
              });
            }
          }
        });

        const result = !isNotCameraDevice
          ? listAvailableCameraGroupsBinding.data.filter(
              (value: Content) => cameraGroupIdSelected !== value.id
            )
          : listAvailableOrigin.current.filter(
              (value: Content) => cameraGroupIdSelected !== value.id
            );

        setListAvailableCameraGroupsBinding((prev) => ({
          ...prev,
          data: result
        }));
        setCameraGroupIdSelected(null);
      }
    } catch (error) {
      setBindGroupAction({
        isLoading: false,
        isSuccess: false,
        isProcessing: false,
        payload: undefined
      });

      if (isApiErrorResponse(error)) {
        const { error: responseError } = error as ApiErrorResponse;

        if (responseError == invalidTypeError.error) {
          openNotification({
            type: 'error',
            title: `${t('actions.bind')} ${t('devicePage.sections.bindCameraGroup.entity')}`,
            description: t('errors.custom.deviceGroup.bindCameraToDecommissionedGroupError')
          });
          return;
        }
      }

      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        entity: t('devicePage.sections.bindCameraGroup.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.bind')} ${t('devicePage.sections.bindCameraGroup.entity')}`,
        description: message
      });
    }
  };

  const handleCancelBindingCameraGroup = () =>
    setBindGroupAction((prev) => ({ ...prev, isProcessing: false, isLoading: false }));

  const handleBindActionChange = (data: {
    isSuccess: boolean;
    isProcessing: boolean;
    isLoading: boolean;
  }) => setBindGroupAction(data);

  const handleUnassignActionChange = (data: { isProcessing: boolean; isSuccess: boolean }) =>
    setUnAssignAction(data);

  async function handleUnAssignAccount() {
    if (unAssignAction.isProcessing || !currentDevice || !accountUnassignSelected) return;
    setUnAssignAction({ isProcessing: true, isSuccess: false });
    try {
      const [
        resUnassignAccount,
        resUpdateDeviceServer,
        resUpdateDeviceCamera,
        resUnbindCameraGroup
      ] = await Promise.allSettled([
        await deviceRepository.updateDeviceAccount({
          deviceId: currentDevice.id,
          accountId: accountUnassignSelected
        }),
        await deviceRepository.updateDeviceServers({
          deviceId: currentDevice.id,
          serverIds: ''
        }),
        await Promise.allSettled(
          currentDevice.cameras.map(async (camera) => {
            const currentLens = camera.lens ?? '';
            const currentCameraName = camera.name ?? '';
            const res = await deviceRepository.updateCamera({
              cameraId: camera.id,
              lens: currentLens,
              name: currentCameraName,
              serviceId: null,
              thermogServiceId: null
            });
            return res;
          })
        ),
        await deviceRepository.unbindCameraGroups({
          deviceId: currentDevice.id,
          cameraGroupIds: ''
        })
      ]);

      const isUnAssignSuccess =
        resUnassignAccount.status === 'fulfilled' &&
        resUnassignAccount.value === EHttpStatusCode.OK;

      const isUpdateDeviceServerSuccess =
        resUpdateDeviceServer.status === 'fulfilled' &&
        resUpdateDeviceServer.value === EHttpStatusCode.OK;

      const isUpdateDeviceCameraSuccess =
        resUpdateDeviceCamera.status === 'fulfilled' &&
        (resUpdateDeviceCamera.value.length <= 0 ||
          resUpdateDeviceCamera.value.filter(
            (status) => status.status === 'fulfilled' && status.value === EHttpStatusCode.OK
          ).length > 0);

      const isUnbindCameraGroupSuccess =
        resUnbindCameraGroup.status === 'fulfilled' &&
        resUnbindCameraGroup.value === EHttpStatusCode.OK;

      const isSuccessfully =
        isUnAssignSuccess &&
        isUpdateDeviceServerSuccess &&
        isUpdateDeviceCameraSuccess &&
        isUnbindCameraGroupSuccess;

      if (isSuccessfully) {
        setUnAssignAction({ isProcessing: false, isSuccess: true });
        openNotification({
          type: 'success',
          title: `${t('devicePage.sections.unassign.title')}`,
          description: `${t('components.success')}`
        });
        setListAvailableCameraGroupsBinding((prev) => ({
          ...prev,
          data: []
        }));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('devicePage.sections.unassign.title'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('devicePage.sections.unassign.title')}`,
        description: message
      });
    }
  }

  const handleValidateSerialNumberReplaceForm = async (serialNumber: string) => {
    if (
      !serialNumber ||
      !currentDevice ||
      serialNumber?.trim().length === 0 ||
      replaceAction.isDeviceChecking
    ) {
      return;
    }
    setReplaceAction((prev) => ({
      ...prev,
      isDeviceChecking: true
    }));
    try {
      const { data, code } = await deviceRepository.getListDevices({
        deviceTypeName: currentDevice.typeName,
        search: `*${serialNumber?.trim()}*`,
        page: DEFAULT_PAGE_NUMBER
      });

      if (data.length > 1) {
        throw multipleResourcesFound;
      }

      if (data.length <= 0) {
        throw resourceNotFound;
      }

      if (data[0]?.serialNumber.trim() === currentDevice?.serialNumber?.trim()) {
        throw replaceDeviceItselfError;
      }

      if (data[0]?.account?.type?.name !== ECustomerType.INVENTORY_CUSTOMER) {
        throw invalidTypeError;
      }

      if (code === EHttpStatusCode.OK) {
        setReplaceAction((prev) => ({
          ...prev,
          isDeviceChecking: false,
          payload: data[0]
        }));
        destroy();
        markAsReplaceDeviceModalOpen();
      }
    } catch (error: unknown) {
      setReplaceAction({
        isSuccess: false,
        isDeviceChecking: false,
        isProcessing: false,
        isLoading: false
      });
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        actionStr: t('components.replace'),
        action: 'other',
        identifier: t('devicePage.entity').toLowerCase(),
        entity: t('devicePage.sections.replace.title'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('devicePage.sections.replace.title')}`,
        description: message
      });
    }
  };

  const handleReplaceDevice = async () => {
    const serialNumber = replaceForm.getFieldValue('serialNumber');
    if (
      !currentDevice?.id ||
      !accountUnassignSelectedModal ||
      serialNumber.trim().length === 0 ||
      !replaceAction.payload
    )
      return;
    setReplaceAction((prev) => ({
      ...prev,
      isProcessing: true
    }));
    try {
      const replaceDeviceCode = await deviceRepository.replaceDevice({
        serialNumber: replaceAction.payload.serialNumber.trim(),
        deviceId: currentDevice.id,
        unassignedAccountId: accountUnassignSelectedModal
      });
      if (replaceDeviceCode === EHttpStatusCode.OK) {
        setReplaceAction({
          isProcessing: false,
          isSuccess: true,
          isDeviceChecking: false,
          isLoading: true
        });
        openNotification({
          type: 'success',
          title: `${t('devicePage.sections.replace.title')}`,
          description: `${t('components.success')}`
        });
        replaceForm.resetFields();
        navigate({
          pathname: `${Routes.EDevicesRoutes.INDEX}/${currentDevice?.typeName.toLowerCase()}/${
            replaceAction.payload.id
          }`,
          search: searchParams.toString()
        });
        setReplaceAction((prev) => ({
          ...prev,
          isSuccess: true,
          isProcessing: false
        }));
      }
    } catch (error) {
      setReplaceAction({
        isSuccess: false,
        isProcessing: false,
        isDeviceChecking: false,
        isLoading: false,
        payload: undefined
      });
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        actionStr: t('components.replace'),
        action: 'other',
        identifier: t('devicePage.entity').toLowerCase(),
        entity: t('devicePage.sections.replace.title'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('devicePage.sections.replace.title')}`,
        description: message
      });
    } finally {
      markAsReplaceDeviceModalClose();
    }
  };

  const handleCancelUnassignAccount = () =>
    setUnAssignAction((prev) => ({ ...prev, isProcessing: false }));

  function handleSearch(searchTextVal: string) {
    if (searchTextVal) {
      searchParams.set('search', searchTextVal);
      searchParams.set('page', DEFAULT_PAGE_NUMBER.toString());

      if (deviceId) {
        navigate({
          pathname: `${Routes.EDevicesRoutes.INDEX}/${(
            currentDeviceType.name ?? ''
          ).toLowerCase()}`,
          search: searchParams.toString()
        });
      } else {
        setSearchParams(searchParams);
      }

      if (searchText === searchTextVal) {
        handleFetchList({
          search: searchTextVal,
          pageNumber: DEFAULT_PAGE_NUMBER,
          deviceTypeName: currentDeviceType.name,
          sortKey,
          order
        });
        return;
      }
    } else {
      navigate({
        pathname: `${Routes.EDevicesRoutes.INDEX}/${(currentDeviceType.name ?? '').toLowerCase()}`
      });
    }
  }

  const handleRebootDevice = async () => {
    if (!deviceId || Number.isNaN(Number(deviceId))) return;
    try {
      const code = await deviceRepository.rebootDevice({
        deviceId: Number(deviceId),
        commandId: EDeviceCommand.REBOOT
      });
      if (code === EHttpStatusCode.OK) {
        setRebootAction({
          isSuccess: true,
          isProcessing: false
        });
        openNotification({
          type: 'success',
          title: t('devicePage.sections.reboot.title'),
          description: `${t('components.success')}`
        });
      }
    } catch (error) {
      setRebootAction({
        isSuccess: false,
        isProcessing: false
      });
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('devicePage.sections.reboot.title'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('devicePage.sections.reboot.title')}`,
        description: message
      });
    }
  };

  const handleRebootActionChange = (action: { isProcessing: boolean; isSuccess: boolean }) =>
    setRebootAction(action);

  const handleCancelRebootDevice = () =>
    setRebootAction((prev) => ({ ...prev, isProcessing: false, isLoading: false }));

  useEffect(() => {
    if (intervalActionTrigger.value) {
      setListAvailableCameraGroupsBinding((prev) => ({
        ...prev,
        data: listAvailableOrigin.current.filter(
          (c) => !intervalActionTrigger.payload.includes(c.id)
        )
      }));

      if (cameraGroupIdSelected && intervalActionTrigger.payload.includes(cameraGroupIdSelected)) {
        const filteredList = listAvailableOrigin.current.filter(
          (c) => !intervalActionTrigger.payload.includes(c.id)
        );
        if (filteredList && filteredList[0]) {
          setCameraGroupIdSelected(filteredList[0]?.id || null);
        }
      }
      setIntervalActionTrigger({
        payload: [],
        value: false
      });
    }
  }, [intervalActionTrigger]);

  useEffect(() => {
    if (currentDeviceType) {
      searchForm.setFieldValue('searchText', searchText);
    }
  }, [searchText, currentDeviceType, location]);

  useEffect(() => {
    if (!deviceId) {
      if (searchText && currentDeviceType) {
        handleFetchList({
          deviceTypeName: currentDeviceType.name,
          search: searchText,
          pageNumber: Number(page),
          sortKey,
          order
        });
      } else {
        setListDevices({
          data: [],
          isLoading: false,
          paging: { pageLimit: DEFAULT_LIMIT, pageNum: DEFAULT_PAGE_NUMBER, total: 0, totalPage: 0 }
        });
      }
    }
  }, [searchText, currentDeviceType, deviceId, page, sortKey, order]);

  useEffect(() => {
    if (!deviceId) {
      setUnAssignAction({ isProcessing: false, isSuccess: false });
      setBindGroupAction({ isProcessing: false, isSuccess: false, isLoading: false });
    }
  }, [deviceId]);

  useEffect(() => {
    if (currentDevice?.account.id) {
      fetchListAccount({ accountIdFilter: currentDevice.account.id });
    }
  }, [currentDevice?.account]);

  useEffect(() => {
    if (currentDevice) {
      handleFetchAllCameraGroupsList({
        deviceId: currentDevice.id
      });
    }
  }, [currentDevice]);

  useEffect(() => {
    onSetCurrentDeviceType(currentDeviceType);
  }, [currentDeviceType]);

  useEffect(() => {
    if (!deviceId) {
      setCameraGroupIdSelected(null);
      setListAvailableCameraGroupsBinding({
        loading: false,
        data: [],
        pagination: {
          total: 0,
          pageNum: DEFAULT_PAGE_NUMBER - 1,
          pageLimit: DEFAULT_LIMIT,
          totalPage: 0
        }
      });
    }

    return () => {
      replaceForm.resetFields();
    };
  }, [deviceId]);

  useEffect(() => {
    if (deviceId && openReplaceDeviceModal && listAccountsSU.length > 0) {
      setAccountUnassignSelectedModal(listAccountsSU[0].id);
    }
  }, [deviceId, listAccountsSU, openReplaceDeviceModal]);

  useEffectOnce(() => {
    if (isActive) {
      loader.complete();
    }
  }, [isActive]);

  return {
    openReplaceDeviceModal,
    searchValue,
    selectedRegions,
    cameraGroupIdSelected,
    accountUnassignSelected,
    accountUnassignSelectedModal,
    listAvailableCameraGroupsBinding,
    currentDevice,
    currentDeviceType,
    listDevices,
    deviceId,
    searchParams,
    searchText,
    searchForm,
    replaceForm,
    replaceAction,
    listAccountsSU,
    listAccountsService,
    bindGroupAction,
    unAssignAction,
    rebootAction,
    listAccountsValid,
    leftColumnOpen,
    markAsLeftColumnOpen,
    markAsLeftColumnClose,
    setAccountUnassignSelected,
    onCameraGroupIdSelectedChange: (value: number | null) => setCameraGroupIdSelected(value),
    setCurrentDevice,
    setSearchParams,
    handleSearch,
    handleResetSearch,
    handleBindCameraGroup,
    handleCancelBindingCameraGroup,
    handleUnAssignAccount,
    handleCancelUnassignAccount,
    handleBindActionChange,
    handleUnassignActionChange,
    handleRebootDevice,
    handleRebootActionChange,
    handleCancelRebootDevice,
    onSearchValueChange: (value: string) => setSearchValue(value),
    onSelectedRegionsChange: (value: string[]) => setSelectedRegions(value),
    onReplaceDevice: handleReplaceDevice,
    onCancelReplaceDevice: () => {
      setReplaceAction((prev) => ({ ...prev, isProcessing: false }));
      markAsReplaceDeviceModalClose();
    },
    onAccountUnassignSelectedModalChanged: (value: number) =>
      setAccountUnassignSelectedModal(value),
    onResetReplaceActionLoading: () => setReplaceAction((prev) => ({ ...prev, isLoading: false })),
    onValidateSerialNumberReplaceForm: handleValidateSerialNumberReplaceForm,
    onIntervalActionTriggerChange: (data: { payload: number[]; value: boolean }) =>
      setIntervalActionTrigger(data)
  };
};
