export const USER_GUIDE_DOC_BASE_URL = 'https://eoss.eyecast.com/help';

export enum EDocEntity {
  ADMINISTRATOR = 'administrator',
  DOMAIN = 'domain',
  CUSTOMER = 'customer',
  DEVICE = 'device',
  DEVICE_GROUP = 'device-group',
  EYEVIEW_USER = 'eyeview-user'
}

export enum EDocSubRouter {
  CREATE = 'create',
  UPDATE = 'update',
  LISTING = 'listing',
  DETAILS = 'details'
}

export const eossDocLinks = {
  device: {
    listing: {
      searchDevices: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE}/${EDocSubRouter.LISTING}/#search-devices`,
      listingTables: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE}/${EDocSubRouter.LISTING}/#listing-tables`
    },
    details: {
      overViewSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE}/${EDocSubRouter.DETAILS}/#device-overview-section`,
      informationSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE}/${EDocSubRouter.DETAILS}/#device-information-section`,
      systemRuntimeSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE}/${EDocSubRouter.DETAILS}/#device-system-runtime-stats-section`,
      statsSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE}/${EDocSubRouter.DETAILS}/#stats-section`,
      groupBindingSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE}/${EDocSubRouter.DETAILS}/#device-group-binding-section-and-bind-device-group-action-box`,
      unassignServiceSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE}/${EDocSubRouter.DETAILS}/#unassign-service-device-action-box`,
      rebootServiceAction: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE}/${EDocSubRouter.DETAILS}/#reboot-service-device-action-box`
    }
  },
  deviceGroup: {
    listing: {
      searchDeviceGroup: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.LISTING}/#search-device-groups`,
      listingTable: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.LISTING}/#listing-table`
    },
    details: {
      generalSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.DETAILS}/#general-section`,
      bindCameraAction: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.DETAILS}/#bind-camera-action-box-and-camera-binding-section`,
      deviceBindingSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.DETAILS}/#devicecamera-binding-section`,
      syncCameraAction: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.DETAILS}/#sync-camera-service-plan-action-box`,
      assignEyeviewUserAction: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.DETAILS}/#assign-eyeview-user-to-a-device-group`,
      assignEyeviewUserSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.DETAILS}/#assign-eyeview-user-action-box-and-eyeview-user-assignment-section`,
      deleteDeviceGroupAction: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.DETAILS}/#delete-device-group-action-box`,
      astroDeviceSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.DETAILS}/#astro-device`,
      upsDeviceSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.DETAILS}/#ups-monitor-section`
    },
    create: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DEVICE_GROUP}/${EDocSubRouter.CREATE}`
  },
  eyeviewUser: {
    listing: {
      searchEyeviewUser: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.EYEVIEW_USER}/${EDocSubRouter.LISTING}/#search-eyeview-users`,
      listingTable: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.EYEVIEW_USER}/${EDocSubRouter.LISTING}/#listing-table`
    },
    details: {
      generalSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.EYEVIEW_USER}/${EDocSubRouter.DETAILS}`,
      assignDeviceGroupAction: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.EYEVIEW_USER}/${EDocSubRouter.DETAILS}/#assign-a-device-group-to-the-eyeview-user`,
      assignDeviceGroupSection: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.EYEVIEW_USER}/${EDocSubRouter.DETAILS}/#assign-device-group-action-box-and-device-group-section`
    },
    create: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.EYEVIEW_USER}/${EDocSubRouter.CREATE}`
  },
  administrator: {
    listing: {
      searchAdministrator: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.ADMINISTRATOR}/${EDocSubRouter.LISTING}/#search-administrators`,
      listingTable: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.ADMINISTRATOR}/${EDocSubRouter.LISTING}/#listing-table`
    },
    create: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.ADMINISTRATOR}/${EDocSubRouter.CREATE}`,
    update: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.ADMINISTRATOR}/${EDocSubRouter.UPDATE}`
  },
  domain: {
    listing: {
      searchDomain: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DOMAIN}/${EDocSubRouter.LISTING}/#search-domains`,
      listingTable: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DOMAIN}/${EDocSubRouter.LISTING}/#listing-table`
    },
    create: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DOMAIN}/${EDocSubRouter.CREATE}`,
    update: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.DOMAIN}/${EDocSubRouter.UPDATE}`
  },
  customer: {
    listing: {
      searchCustomer: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.CUSTOMER}/${EDocSubRouter.LISTING}/#search-customers`,
      listingTable: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.CUSTOMER}/${EDocSubRouter.LISTING}/#listing-table`
    },
    create: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.CUSTOMER}/${EDocSubRouter.CREATE}`,
    update: `${USER_GUIDE_DOC_BASE_URL}/${EDocEntity.CUSTOMER}/${EDocSubRouter.UPDATE}`
  }
};
