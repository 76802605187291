import { useCallback } from 'react';

import { useStateRef } from './useStateRef';

/**
 * A React hook that ensures the latest version of a callback function is always invoked without causing unnecessary re-renders or dependency changes in useCallback.
 * @param callback The function you want to memoize while ensuring the latest version is used.
 * @return A memoized function of the same type as the provided callback.
 */
export default function useLastCallback<T extends (...args: any[]) => any>(callback?: T) {
  const ref = useStateRef(callback);

  return useCallback((...args: Parameters<T>) => ref.current?.(...args), []) as T;
}
