import { Button, Popover, Select, Switch, Tooltip } from 'antd';
import { DropdownIcon } from 'assets/icons';
import { eossDocLinks } from 'constant';
import {
  ECameraBindingSwitchOption,
  EDeviceType,
  ESortingDevicePage,
  ESwitchTriggerDeviceBindingRefresh
} from 'enums';
import { Section, ToggleOption } from 'presentation/components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CiFilter } from 'react-icons/ci';
import TableCameraBinding from './TableCameraBinding';
import TableOtherDeviceBinding from './TableOtherDeviceBinding';
import { SegmentedValue } from 'antd/es/segmented';
import type { DataList, TCameraBinding, TOtherDevice } from 'models';

type Props = {
  isInEdit: boolean;
  isShowRefreshButton: boolean;
  cameraBindingSwitchValue: ECameraBindingSwitchOption;
  orderSelectValueDeviceTab: number | null;
  orderSelectValueCameraTab: number;
  deviceBindingSummary: {
    totalCameras: number;
    totalDevices: number;
  };
  warningMessage: string;
  listCameraBinding: DataList<
    (TCameraBinding & {
      key: string;
    })[]
  >;
  cameraGroupSelectedRow: React.Key[];
  astroDeviceList: DataList<TOtherDevice[]>;
  astroDeviceSelectedRow: React.Key[];
  upsMonitorList: DataList<TOtherDevice[]>;
  upsMonitorSelectedRow: React.Key[];
  onOrderTableCameraBindingChange: (value: number) => void;
  onInEditChange: (value: boolean) => void;
  onCameraBindingRefresh: () => void;
  onCameraBindingSwitchChange: (value: SegmentedValue) => void;
  onCameraBindingListChange: (value: {
    data: Array<
      TCameraBinding & {
        key: string;
      }
    >;
    loading: boolean;
  }) => void;
  onCameraGroupSelectedRowChange: (value: React.Key[]) => void;
  onAstroDeviceRefresh: () => void;
  onAstroDeviceSelectedRowChange: (value: React.Key[]) => void;
  onUpsMonitorRefresh: () => void;
  onUpsMonitorSelectedRowChange: (value: React.Key[]) => void;
};

const SWITCH_FIXED_WIDTH = 130;

const DeviceBinding: React.FC<Props> = ({
  isInEdit,
  isShowRefreshButton,
  cameraBindingSwitchValue,
  orderSelectValueCameraTab,
  orderSelectValueDeviceTab,
  deviceBindingSummary,
  warningMessage,
  listCameraBinding,
  cameraGroupSelectedRow,
  astroDeviceList,
  astroDeviceSelectedRow,
  upsMonitorList,
  upsMonitorSelectedRow,
  onOrderTableCameraBindingChange,
  onInEditChange,
  onCameraBindingRefresh,
  onCameraBindingSwitchChange,
  onCameraBindingListChange,
  onCameraGroupSelectedRowChange,
  onAstroDeviceRefresh,
  onAstroDeviceSelectedRowChange,
  onUpsMonitorRefresh,
  onUpsMonitorSelectedRowChange
}) => {
  const { t } = useTranslation();

  const menuItems = useMemo(
    () =>
      [
        {
          label: '-',
          value: ESortingDevicePage.NO_SORT,
          visible: true
        },
        {
          label: `${t('cameraGroupPage.cameraBindingTable.cameraName')} (${t(
            'cameraGroupPage.cameraBindingTable.asc'
          )})`,
          value: ESortingDevicePage.ORDER_ASC_BY_CAMERA_NAME,
          visible: cameraBindingSwitchValue === ECameraBindingSwitchOption.Camera
        },
        {
          label: `${t('cameraGroupPage.cameraBindingTable.cameraName')} (${t(
            'cameraGroupPage.cameraBindingTable.desc'
          )})`,
          value: ESortingDevicePage.ORDER_DESC_BY_CAMERA_NAME,
          visible: cameraBindingSwitchValue === ECameraBindingSwitchOption.Camera
        },
        {
          label: `${t('cameraGroupPage.cameraBindingTable.serialNumber')} (${t(
            'cameraGroupPage.cameraBindingTable.asc'
          )})`,
          value: ESortingDevicePage.ORDER_ASC_BY_SERIAL_NUMBER,
          visible: true
        },
        {
          label: `${t('cameraGroupPage.cameraBindingTable.serialNumber')} (${t(
            'cameraGroupPage.cameraBindingTable.desc'
          )})`,
          value: ESortingDevicePage.ORDER_DESC_BY_SERIAL_NUMBER,
          visible: true
        }
      ].filter((value) => value.visible),
    [cameraBindingSwitchValue, t]
  );

  return (
    <>
      <Section
        docLink={eossDocLinks.deviceGroup.details.deviceBindingSection}
        title={
          <div className="d-flex align-items-center gap-2">
            <div>
              {(cameraBindingSwitchValue ?? '').toLowerCase() ===
              ECameraBindingSwitchOption.Device.toLowerCase()
                ? t('cameraGroupPage.sections.deviceBind.title')
                : t('cameraGroupPage.sections.cameraBind.title')}
            </div>
            <Popover
              trigger="click"
              content={
                <div className="p-3" onClick={(e) => e.stopPropagation()}>
                  <div className="fs-12">{t('cameraGroupPage.cameraBindingTable.sortBy')}</div>
                  <div
                    style={{
                      width: 180
                    }}
                  >
                    <Select
                      className="w-100"
                      optionFilterProp="children"
                      defaultValue={0}
                      value={
                        cameraBindingSwitchValue === ECameraBindingSwitchOption.Device
                          ? orderSelectValueDeviceTab
                          : orderSelectValueCameraTab
                      }
                      options={menuItems}
                      suffixIcon={<DropdownIcon />}
                      onChange={(value: number) => {
                        onOrderTableCameraBindingChange(value);
                      }}
                    />
                  </div>
                </div>
              }
              placement="right"
            >
              <Button
                size="small"
                icon={<CiFilter color="var(--persian-blue)" />}
                shape="circle"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popover>
            <Tooltip
              title={
                isInEdit
                  ? t('cameraGroupPage.sections.autoRefresh.tooltip.editMode')
                  : t('cameraGroupPage.sections.autoRefresh.tooltip.viewMode')
              }
              trigger="hover"
              destroyTooltipOnHide
            >
              <Switch
                value={isInEdit}
                checkedChildren={ESwitchTriggerDeviceBindingRefresh.IN_EDIT}
                unCheckedChildren={ESwitchTriggerDeviceBindingRefresh.DATA_VIEW}
                defaultChecked
                style={{
                  width: SWITCH_FIXED_WIDTH
                }}
                onChange={onInEditChange}
              />
            </Tooltip>
          </div>
        }
        onClickRefresh={isShowRefreshButton ? onCameraBindingRefresh : undefined}
        headerStyle={{ paddingTop: 0, paddingBottom: 0 }}
        extraControl={
          <ToggleOption
            value={cameraBindingSwitchValue}
            options={[ECameraBindingSwitchOption.Device, ECameraBindingSwitchOption.Camera]}
            onToggleChange={onCameraBindingSwitchChange}
          />
        }
        extraFooter={
          <div className="d-flex justify-content-end me-4 fs-12 fw-normal py-2">
            {t('cameraGroupPage.cameraBindingTable.extraFooter.label', {
              camera: `${deviceBindingSummary.totalCameras} ${t('camera.entity').toLowerCase()}${
                deviceBindingSummary.totalCameras > 1 ? 's' : ''
              }`,
              device: `${deviceBindingSummary.totalDevices} ${t(
                'devicePage.entity'
              ).toLowerCase()}${deviceBindingSummary.totalDevices > 1 ? 's' : ''}`
            })}
          </div>
        }
      >
        {warningMessage && (
          <div
            className="fs-12 rounded-4 px-2"
            style={{ backgroundColor: 'var(--sub-1)', color: 'var(--sub-3)' }}
          >
            <span className="fw-medium ms-2">Warning: </span>
            <span>{warningMessage}</span>
          </div>
        )}
        <TableCameraBinding
          listCameraBinding={listCameraBinding}
          cameraBindingSwitchValue={cameraBindingSwitchValue}
          cameraGroupSelectedRow={cameraGroupSelectedRow}
          onCameraBindingListChange={onCameraBindingListChange}
          onCameraGroupSelectedRowChange={onCameraGroupSelectedRowChange}
        />
      </Section>

      {(astroDeviceList.data.length > 0 || astroDeviceList.loading) && (
        <Section
          title={t('cameraGroupPage.sections.astroDevice.title')}
          onClickRefresh={onAstroDeviceRefresh}
          docLink={eossDocLinks.deviceGroup.details.astroDeviceSection}
        >
          <TableOtherDeviceBinding
            deviceType={EDeviceType.ASTRO_CAMERA}
            deviceList={astroDeviceList}
            deviceSelectedRow={astroDeviceSelectedRow}
            onDeviceSelectedRowChange={onAstroDeviceSelectedRowChange}
          />
        </Section>
      )}

      {(upsMonitorList.data.length > 0 || upsMonitorList.loading) && (
        <Section
          title={t('cameraGroupPage.sections.ups.title')}
          onClickRefresh={onUpsMonitorRefresh}
          docLink={eossDocLinks.deviceGroup.details.upsDeviceSection}
        >
          <TableOtherDeviceBinding
            deviceType={EDeviceType.UPS_MONITOR}
            deviceList={upsMonitorList}
            deviceSelectedRow={upsMonitorSelectedRow}
            onDeviceSelectedRowChange={onUpsMonitorSelectedRowChange}
          />
        </Section>
      )}
    </>
  );
};

export { DeviceBinding };
