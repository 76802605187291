import { DatePicker, Form, Input, Tooltip } from 'antd';
import { DATE_FORMAT, eossDocLinks } from 'constant';
import { useTranslation } from 'react-i18next';
import { DateIcon, MoreInfoIcon } from 'assets/icons';
import dayjs from 'dayjs';
import { Section } from 'presentation/components';
import { memo } from 'react';
import styles from './billing.module.scss';
import { FormInstance } from 'antd/es/form/Form';
import { TBilling } from 'models';
import useResponsive from 'hooks/useResponsive';
import ValidationUtilities from 'utils/validationUtils';

type BillingProps = {
  form: FormInstance<TBilling>;
};

export const Billing = memo(({ form }: BillingProps) => {
  const { t } = useTranslation();
  const { isTablet, isSemiDesktop } = useResponsive();

  return (
    <Section
      docLink={eossDocLinks.customer.update}
      className={styles.container}
      title={t('accountPage.sections.billing.title')}
      classNameBody="p-3"
      allowCollapse
      expandedByDefault
      keyPanel="customer-billing"
    >
      <Form
        form={form}
        className="w-100"
        layout={isTablet || isSemiDesktop ? 'horizontal' : 'vertical'}
        colon={false}
        labelCol={{
          style: {
            width: 70
          }
        }}
      >
        <div id="customerPage_billingForm" className="row">
          <div className="col-12 col-sm-6 col-md-12 col-slg-6">
            <Form.Item
              label={
                <div className="d-flex align-items-center">
                  <span>{t('forms.billingRange.start.label')}</span>
                  <Tooltip
                    title={<span className="text-nowrap">{DATE_FORMAT}</span>}
                    trigger="click"
                  >
                    <div className="d-flex align-items-center cursor-pointer">
                      <MoreInfoIcon />
                    </div>
                  </Tooltip>
                </div>
              }
              name={['billing', 'start']}
              dependencies={[['billing', 'stop']]}
              rootClassName="w-100"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, start) {
                    if (
                      !start ||
                      dayjs(start).isBefore(getFieldValue(['billing', 'stop'])) ||
                      dayjs(start).isSame(getFieldValue(['billing', 'stop'])) ||
                      !getFieldValue(['billing', 'stop'])
                    )
                      return Promise.resolve();
                    return Promise.reject(t('forms.billingRange.start.mustBeBefore'));
                  }
                })
              ]}
            >
              <DatePicker
                format={DATE_FORMAT}
                className="w-100 cursor-pointer"
                suffixIcon={<DateIcon />}
                placeholder=""
              />
            </Form.Item>
          </div>
          <div className="col-12 col-sm-6 col-md-12 col-slg-6">
            <Form.Item
              label={
                <div className="d-flex align-items-center">
                  <span>{t('forms.billingRange.end.label')}</span>
                  <Tooltip
                    title={<span className="text-nowrap">{DATE_FORMAT}</span>}
                    trigger="click"
                  >
                    <div className="d-flex align-items-center cursor-pointer">
                      <MoreInfoIcon />
                    </div>
                  </Tooltip>
                </div>
              }
              className="w-100"
              name={['billing', 'stop']}
              dependencies={[['billing', 'start']]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, end) {
                    if (
                      !end ||
                      dayjs(getFieldValue(['billing', 'start'])).isBefore(end) ||
                      dayjs(getFieldValue(['billing', 'start'])).isSame(end) ||
                      !getFieldValue(['billing', 'start'])
                    )
                      return Promise.resolve();
                    return Promise.reject(t('forms.billingRange.end.mustBeAfter'));
                  }
                })
              ]}
            >
              <DatePicker
                format={DATE_FORMAT}
                className="w-100 cursor-pointer"
                suffixIcon={<DateIcon />}
                placeholder=""
              />
            </Form.Item>
          </div>
          <div className="col-12 col-sm-6 col-md-12 col-slg-6">
            <Form.Item
              name={['billing', 'stateTax']}
              label={<span>{t('forms.billingStateTax.label')}</span>}
              className="w-100"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.taxAndDiscountValidationFn({
                      value,
                      errorMessage: t('forms.billingStateTax.maxLength')
                    })
                }
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-12 col-sm-6 col-md-12 col-slg-6">
            <Form.Item
              name={['billing', 'localTax']}
              label={<span>{t('forms.billingLocalTax.label')}</span>}
              className="w-100"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.taxAndDiscountValidationFn({
                      value,
                      errorMessage: t('forms.billingLocalTax.maxLength')
                    })
                }
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-12 col-sm-6 col-md-12 col-slg-6">
            <Form.Item
              name={['billing', 'discount']}
              label={<span>{t('forms.billingDiscount.label')}</span>}
              className="w-100 mb-0"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.taxAndDiscountValidationFn({
                      value,
                      errorMessage: t('forms.billingDiscount.maxLength')
                    })
                }
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Section>
  );
});
