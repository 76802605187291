import {
  EmptyPage,
  LeftColumn,
  Pagination,
  PermissionWrapper,
  RightColumn,
  SearchSelect,
  Section
} from 'presentation/components';
import { Trans, useTranslation } from 'react-i18next';
import { DEFAULT_LIMIT, eossDocLinks } from 'constant';
import { useDevicesListingPageController } from './DevicesListingPageController';
import TableListDevice from './components/TableListDevice';
import { DeviceEmptyIcon, DropdownIcon } from 'assets/icons';
import { Button, Form, Input, Modal, Result, Select, Spin } from 'antd';
import { Outlet, useMatch } from 'react-router-dom';
import { EDeviceType, ERoleKey, ESubFeatureKey, Routes } from 'enums';
import type { TCameraGroupAssign, TDevice } from 'models';
import { useEffect, useRef } from 'react';
import { usePathPermission } from 'hooks/usePermission';
import styles from './deviceListingPage.module.scss';
import { useAppUtil } from 'context/UtilContext';

type TSearchFieldType = {
  searchText: string;
};

type TReplaceFieldType = {
  serialNumber: string;
};

const DevicesListingPageView = () => {
  const {
    openReplaceDeviceModal,
    searchValue,
    selectedRegions,
    cameraGroupIdSelected,
    deviceId,
    currentDevice,
    listAvailableCameraGroupsBinding,
    searchForm,
    replaceForm,
    replaceAction,
    listDevices,
    searchText,
    searchParams,
    currentDeviceType,
    listAccountsSU,
    listAccountsService,
    bindGroupAction,
    accountUnassignSelected,
    accountUnassignSelectedModal,
    unAssignAction,
    rebootAction,
    listAccountsValid,
    leftColumnOpen,
    markAsLeftColumnOpen,
    markAsLeftColumnClose,
    setSearchParams,
    handleSearch,
    handleResetSearch,
    setCurrentDevice,
    onCameraGroupIdSelectedChange,
    handleBindCameraGroup,
    handleCancelBindingCameraGroup,
    setAccountUnassignSelected,
    handleUnAssignAccount,
    handleCancelUnassignAccount,
    handleBindActionChange,
    handleUnassignActionChange,
    handleRebootDevice,
    handleRebootActionChange,
    handleCancelRebootDevice,
    onSearchValueChange,
    onSelectedRegionsChange,
    onReplaceDevice,
    onCancelReplaceDevice,
    onAccountUnassignSelectedModalChanged,
    onResetReplaceActionLoading,
    onValidateSerialNumberReplaceForm,
    onIntervalActionTriggerChange
  } = useDevicesListingPageController();
  const { t } = useTranslation();
  const isShowImportExportActions = useMatch(Routes.EDevicesRoutes.LISTING);
  const isShowDetailActions = useMatch(Routes.EDevicesRoutes.DETAILED) && currentDevice;

  const { openModal } = useAppUtil();

  const isIPCamera = currentDeviceType?.name === EDeviceType.IP_CAMERA;
  // const isAstro = currentDeviceType?.name === EDeviceType.ASTRO_CAMERA;
  const isThermalCamera = currentDeviceType?.name === EDeviceType.THERMAL_CAMERA;
  const isThermalOpticalCamera = currentDeviceType?.name === EDeviceType.THERMAL_OPTICAL_CAMERA;
  // const isAnalogCamera = currentDeviceType?.name === EDeviceType.ANALOG_CAMERA;
  const isUPSMonitor = currentDeviceType?.name === EDeviceType.UPS_MONITOR;

  const isAllowBindCameraGroup = usePathPermission(
    Routes.ECameraGroupRoutes.LISTING,
    ERoleKey.UPDATE,
    ESubFeatureKey.ASSIGNMENT
  ).allowed;

  // TODO: Temporarily hidden bind action in Astro & APC UPS Device
  const isDeviceCamera = isIPCamera || isThermalCamera || isThermalOpticalCamera;

  const ref = useRef<HTMLDivElement>(null);
  const toolTipRef = useRef<{
    onHideTooltip: () => void;
    onShowTooltip: () => void;
  }>();

  const filterOption = (
    input: string,
    option?: {
      label: string;
      value: string | number;
    }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  function handleOnChangeSort(order?: string | null, columnKey?: string) {
    if (columnKey && order) {
      searchParams.set('sortKey', columnKey?.toString());
      searchParams.set('order', order);
    } else {
      searchParams.delete('sortKey');
      searchParams.delete('order');
    }
    setSearchParams(searchParams);
  }

  const handleFormUnAssignAccountSubmit = () => {
    if (unAssignAction.isProcessing || !currentDevice || !accountUnassignSelected) return;
    handleUnassignActionChange({ isProcessing: true, isSuccess: false });
    const toAccount = listAccountsSU.find((account) => account?.id === accountUnassignSelected);
    openModal({
      title: t('components.confirmationTitle'),
      content: (
        <>
          <div>{t('devicePage.modal.unassign.action')}</div>
          <div>
            {t('components.from')}{' '}
            <span className="fw-medium">{currentDevice?.account.name ?? ''}</span>{' '}
            {t('components.to')} <span className="fw-medium">{toAccount?.name ?? ''}</span>
            {'?'}
          </div>
        </>
      ),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      styles: {
        content: {
          width: 'auto'
        }
      },
      width: 'fit-content',
      onOk: handleUnAssignAccount,
      onCancel: handleCancelUnassignAccount
    });
  };

  const handleFormBindCameraGroupSubmit = () => {
    if (bindGroupAction.isProcessing || !currentDevice || !cameraGroupIdSelected) return;
    handleBindActionChange({ isSuccess: false, isProcessing: true, isLoading: false });
    const toGroup = listAvailableCameraGroupsBinding.data.find(
      (group) => group?.id === cameraGroupIdSelected
    );
    openModal({
      title: t('components.confirmationTitle'),
      content: (
        <>
          <div>{t('devicePage.modal.bind.action')}</div>
          <div>
            {t('header.cameraGroups').toLowerCase()}{' '}
            <span className="fw-medium">{toGroup?.name ?? ''}?</span>
          </div>
        </>
      ),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      styles: {
        content: {
          width: 'auto'
        }
      },
      width: 'fit-content',
      onOk: () => handleBindCameraGroup(),
      onCancel: handleCancelBindingCameraGroup
    });
  };

  const handleRebootDeviceSubmit = () => {
    if (rebootAction.isProcessing) return;
    handleRebootActionChange({ isProcessing: true, isSuccess: false });
    openModal({
      title: t('components.confirmationTitle'),
      content: (
        <>
          <div>{t('devicePage.modal.reboot.action')}</div>
        </>
      ),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      styles: {
        content: {
          width: 'auto'
        }
      },
      width: 'fit-content',
      onOk: handleRebootDevice,
      onCancel: handleCancelRebootDevice
    });
  };

  useEffect(() => {
    if (!deviceId) scrollToTop();
  }, [deviceId]);

  useEffect(() => {
    return () => {
      markAsLeftColumnOpen();
    };
  }, []);

  if (!currentDeviceType)
    return (
      <Result
        status="404"
        title={t('devicePage.404.title')}
        subTitle={t('devicePage.404.subTitleForListing')}
      />
    );

  return (
    <>
      <Modal
        open={openReplaceDeviceModal}
        closable={false}
        onOk={onReplaceDevice}
        onCancel={onCancelReplaceDevice}
        maskClosable={false}
        title="eoss.eyecast.com says"
        okButtonProps={{
          loading: replaceAction.isProcessing
        }}
        style={{
          top: 56
        }}
        styles={{
          content: {
            width: 500
          }
        }}
      >
        <Trans
          i18nKey={'devicePage.modal.replace.actionDetail'}
          values={{
            currentSerialNumber: currentDevice?.serialNumber,
            newSerialNumber: replaceForm.getFieldValue('serialNumber')?.trim()
          }}
        >
          <span className="fw-medium" />
          <span className="fw-medium" />
        </Trans>
        <div>{t('devicePage.modal.replace.action')}</div>
        <Select
          value={accountUnassignSelectedModal}
          className="w-100 mt-2"
          optionFilterProp="children"
          showSearch
          options={listAccountsSU.map((account) => {
            return {
              label: account.name,
              value: account.id
            };
          })}
          filterOption={filterOption}
          onSelect={(value) => {
            onAccountUnassignSelectedModalChanged(value);
          }}
          suffixIcon={<DropdownIcon />}
        />
      </Modal>
      <div className={`py-2 px-1 px-md-3 overflow-hidden flex-fill ${styles.container}`}>
        {!currentDeviceType ? (
          <Spin />
        ) : (
          <div className="h-100 w-100 position-relative">
            {/**
             * LEFT COLUMN
             */}
            <LeftColumn
              withCollapse
              leftColumnOpen={leftColumnOpen}
              onMouseEnter={() => toolTipRef.current?.onShowTooltip()}
              onMouseMove={() => toolTipRef.current?.onShowTooltip()}
              onMouseLeave={() => toolTipRef.current?.onHideTooltip()}
            >
              <Section
                title={t('devicePage.sections.search.title', {
                  deviceTypeName: currentDeviceType.displayName
                })}
                docLink={eossDocLinks.device.listing.searchDevices}
                classNameBody="px-4 pt-3 pb-4"
              >
                <Form
                  id="devicePage_searchAction"
                  form={searchForm}
                  layout="vertical"
                  initialValues={{ customerId: null, groupName: '*' }}
                  onFinish={(values) => {
                    handleSearch(values.searchText);
                  }}
                  onFieldsChange={(changedFields) => {
                    const searchTextField = changedFields.find(
                      (field) => field?.name?.includes('searchText')
                    );
                    if (searchTextField && !searchTextField.value) {
                      handleResetSearch();
                    }
                  }}
                >
                  <div className="mb-0">
                    <Form.Item<TSearchFieldType> name={'searchText'} className="mb-2">
                      <Input className={'search-input'} allowClear />
                    </Form.Item>
                  </div>
                  <p className="fs-12 fw-normal mb-2">
                    {t('devicePage.sections.search.instruction', {
                      deviceTypeName: currentDeviceType.displayName
                    })}
                  </p>
                  <div className="d-flex justify-content-end">
                    <Button
                      htmlType="submit"
                      className={`${styles.actionBtn} btn-fill`}
                      onClick={() => {
                        const event = new CustomEvent('close-sidebar-action');
                        document.dispatchEvent(event);
                      }}
                    >
                      <span className={styles.btnContent}>{t('components.search')}</span>
                    </Button>
                  </div>
                </Form>
              </Section>
              {/**
               * TODO: ERRP-129: Reopen when BE implement these import/export functions
               */}
              {/* {isShowImportExportActions && (
                <>
                  <Section
                    title={t('devicePage.sections.import.title')}
                    classNameBody="px-4 pt-3 pb-4"
                  >
                    <div>
                      <p className="fs-12 fw-normal mb-3">
                        {t('devicePage.sections.import.instruction')}
                      </p>
                      <div className="d-flex justify-content-end">
                        <Button
                          data-testid="btn-import-device"
                          className={`${styles.actionBtn} btn-fill`}
                          onClick={() => console.log('import')}
                          disabled
                        >
                          <span className={styles.btnContent}>
                            {t('devicePage.sections.import.buttonText')}
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Section>
                  <Section
                    title={t('devicePage.sections.export.title')}
                    classNameBody="px-4 pt-3 pb-4"
                  >
                    <div>
                      <p className="fs-12 fw-normal mb-3">
                        {t('devicePage.sections.export.instruction')}
                      </p>
                      <div className="d-flex justify-content-end">
                        <Button
                          className={`${styles.actionBtn} btn-fill`}
                          onClick={() => console.log('import')}
                          disabled
                        >
                          <span className={styles.btnContent}>
                            {t('devicePage.sections.export.buttonText')}
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Section>
                </>
              )} */}
              {isShowDetailActions && (
                <>
                  {listAccountsSU.length > 0 && (
                    <PermissionWrapper.Update subFeature={ESubFeatureKey.SERVICE}>
                      <Section
                        title={t('devicePage.sections.unassign.title')}
                        docLink={eossDocLinks.device.details.unassignServiceSection}
                        classNameBody="px-4 pt-3 pb-4"
                      >
                        <div id="devicePage_unassignAction">
                          <p className="fs-12 fw-normal">
                            <Trans
                              i18nKey={'devicePage.sections.unassign.instruction'}
                              values={{
                                serialNumber: currentDevice?.serialNumber,
                                accountName: currentDevice?.account.name
                              }}
                            >
                              <span className="fw-medium" />
                              <span className="fw-medium" />
                            </Trans>
                          </p>
                          <Select
                            value={accountUnassignSelected}
                            className="w-100 mb-3"
                            showSearch
                            optionFilterProp="children"
                            options={listAccountsSU.map((account) => {
                              return {
                                label: account.name,
                                value: account.id
                              };
                            })}
                            filterOption={filterOption}
                            onSelect={(value) => setAccountUnassignSelected(value)}
                            suffixIcon={<DropdownIcon />}
                          />
                          <div className="d-flex justify-content-end">
                            <Button
                              className={`${styles.actionBtn} btn-error`}
                              onClick={() => {
                                handleFormUnAssignAccountSubmit();
                                const event = new CustomEvent('close-sidebar-action');
                                document.dispatchEvent(event);
                              }}
                            >
                              <span className={styles.btnContent}>
                                {t('devicePage.sections.unassign.buttonText')}
                              </span>
                            </Button>
                          </div>
                        </div>
                      </Section>
                      <Section
                        title={t('devicePage.sections.replace.title')}
                        classNameBody="px-4 pt-3 pb-4"
                      >
                        <Form
                          id="devicePage_replaceAction"
                          form={replaceForm}
                          layout="vertical"
                          initialValues={{ customerId: null, groupName: '*' }}
                          onFinish={(values: TReplaceFieldType) => {
                            onValidateSerialNumberReplaceForm(values.serialNumber);
                          }}
                        >
                          <p className="fs-12 fw-normal mb-2">
                            <Trans
                              i18nKey={'devicePage.sections.replace.instruction'}
                              values={{
                                serialNumber: currentDevice?.serialNumber
                              }}
                            >
                              <span className="fw-medium" />
                            </Trans>
                            <div className="mt-2">{t('devicePage.sections.replace.note')}:</div>
                          </p>
                          <Form.Item<TReplaceFieldType> name="serialNumber" className="mb-3">
                            <Input
                              className={'search-input'}
                              allowClear
                              placeholder="e.g.01103001010112b"
                            />
                          </Form.Item>
                          <div className="d-flex justify-content-end">
                            <Button
                              htmlType="submit"
                              className={`${styles.actionBtn} btn-fill`}
                              style={{
                                width: replaceAction.isDeviceChecking ? 100 : 78
                              }}
                              loading={replaceAction.isDeviceChecking}
                              onClick={() => {
                                const event = new CustomEvent('close-sidebar-action');
                                document.dispatchEvent(event);
                              }}
                            >
                              <span className={styles.btnContent}>{t('components.replace')}</span>
                            </Button>
                          </div>
                        </Form>
                      </Section>
                    </PermissionWrapper.Update>
                  )}

                  <PermissionWrapper roleKey={ERoleKey.EXECUTE} subFeature={ESubFeatureKey.COMMAND}>
                    <Section
                      title={t('devicePage.sections.reboot.title')}
                      docLink={eossDocLinks.device.details.rebootServiceAction}
                      classNameBody="px-4 pt-3 pb-4"
                    >
                      <div id="devicePage_rebootAction">
                        <p className="fs-12 fw-normal">
                          <Trans
                            i18nKey={'devicePage.sections.reboot.instruction'}
                            values={{
                              serialNumber: currentDevice?.serialNumber
                            }}
                          >
                            <span className="fw-medium" />
                          </Trans>
                        </p>
                        <div className="d-flex justify-content-end">
                          <Button
                            className={`${styles.actionBtn} btn-fill`}
                            onClick={() => {
                              handleRebootDeviceSubmit();
                              const event = new CustomEvent('close-sidebar-action');
                              document.dispatchEvent(event);
                            }}
                          >
                            <span className={styles.btnContent}>
                              {t('devicePage.sections.reboot.buttonText')}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Section>
                  </PermissionWrapper>
                  {currentDevice &&
                    isAllowBindCameraGroup &&
                    isDeviceCamera &&
                    (listAvailableCameraGroupsBinding.data.length > 0 ||
                      listAvailableCameraGroupsBinding.loading) && (
                      <Section
                        title={t('devicePage.sections.bindCameraGroup.title')}
                        docLink={eossDocLinks.device.details.groupBindingSection}
                        classNameBody="px-4 pt-3 pb-4"
                      >
                        <div id="devicePage_bindDeviceGroupAction">
                          <p className="fs-12 fw-normal">
                            <Trans
                              i18nKey={'devicePage.sections.bindCameraGroup.instruction'}
                              values={{
                                serialNumber: currentDevice?.serialNumber
                              }}
                            >
                              <span className="fw-medium" />
                            </Trans>
                          </p>
                          <SearchSelect<TCameraGroupAssign>
                            className="w-100 mb-3"
                            placeholder={
                              listAvailableCameraGroupsBinding.loading
                                ? t('components.loading')
                                : t('devicePage.sections.dropDownSearchDeviceGroup.title')
                            }
                            searchValue={searchValue}
                            selectedRegions={selectedRegions}
                            value={cameraGroupIdSelected}
                            loading={listAvailableCameraGroupsBinding.loading}
                            options={listAvailableCameraGroupsBinding.data}
                            suffixIcon={
                              listAvailableCameraGroupsBinding.loading ? undefined : (
                                <DropdownIcon />
                              )
                            }
                            style={{
                              pointerEvents: listAvailableCameraGroupsBinding.loading
                                ? 'none'
                                : 'unset'
                            }}
                            onSelectChange={(value: number | null) => {
                              onCameraGroupIdSelectedChange(value);
                            }}
                            onSearchValueChange={onSearchValueChange}
                            onSelectedRegionsChange={onSelectedRegionsChange}
                          />
                          <div className="d-flex justify-content-end">
                            <Button
                              className={`${styles.actionBtn} btn-fill`}
                              onClick={() => {
                                handleFormBindCameraGroupSubmit();
                                const event = new CustomEvent('close-sidebar-action');
                                document.dispatchEvent(event);
                              }}
                              disabled={
                                !cameraGroupIdSelected || listAvailableCameraGroupsBinding.loading
                              }
                            >
                              <span className={styles.btnContent}>
                                {t('devicePage.sections.bindCameraGroup.buttonText')}
                              </span>
                            </Button>
                          </div>
                        </div>
                      </Section>
                    )}
                  {/* <PermissionWrapper roleKey={ERoleKey.EXECUTE} subFeature={ESubFeatureKey.COMMAND}>
                    <Section
                      title={t('devicePage.sections.scanNetwork.title')}
                      classNameBody="px-4 pt-3 pb-4"
                    >
                      <div>
                        <p className="fs-12 fw-normal">
                          {t('devicePage.sections.scanNetwork.instruction')}
                        </p>
                        <div className="d-flex justify-content-end">
                          <Button className={`${styles.actionBtn} btn-fill`} disabled>
                            <span className={styles.btnContent}>
                              {t('devicePage.sections.scanNetwork.buttonText')}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Section>

                    <Section
                      title={t('devicePage.sections.speedTest.title')}
                      classNameBody="px-4 pt-3 pb-4"
                    >
                      <div>
                        <p className="fs-12 fw-normal">
                          {t('devicePage.sections.speedTest.instruction')}
                        </p>
                        <div className="d-flex justify-content-end">
                          <Button className={`${styles.actionBtn} btn-fill px-6`} disabled>
                            <span className={styles.btnContent}>
                              {t('devicePage.sections.speedTest.buttonText')}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Section>
                  </PermissionWrapper> */}
                </>
              )}
            </LeftColumn>

            {/**
             * RIGHT COLUMN
             */}
            <RightColumn
              toolTipRef={toolTipRef}
              loading={replaceAction.isLoading}
              leftColumnOpen={leftColumnOpen}
              onSideBarClick={() => {
                leftColumnOpen ? markAsLeftColumnClose() : markAsLeftColumnOpen();
              }}
            >
              {deviceId ? (
                <Outlet
                  context={{
                    bindGroupAction,
                    unAssignAction,
                    listAccountsService,
                    listAccountsValid,
                    onSetCurrentDevice: (device: TDevice | null) => setCurrentDevice(device),
                    scrollToTop,
                    onResetReplaceActionLoading,
                    onIntervalActionTriggerChange
                  }}
                />
              ) : (
                <>
                  <Section
                    title={t('devicePage.sections.listing.title', {
                      deviceTypeName: currentDeviceType.displayName
                    })}
                    docLink={eossDocLinks.device.listing.listingTables}
                  >
                    <EmptyPage
                      showEmpty={!searchText}
                      title={t('components.getStarted')}
                      subTitle={t('devicePage.emptyTable.subTitle', {
                        deviceType: currentDeviceType.displayName
                      })}
                      emptyIcon={<DeviceEmptyIcon />}
                    >
                      <TableListDevice
                        isLoading={listDevices.isLoading}
                        listDevices={listDevices.data}
                        onChangeSort={handleOnChangeSort}
                        isIPCamera={isIPCamera}
                        isThermalCamera={isThermalCamera}
                        isThermalOpticalCamera={isThermalOpticalCamera}
                        isUPSMonitor={isUPSMonitor}
                      />
                    </EmptyPage>
                  </Section>
                  {Boolean(listDevices.paging.total) && searchText && (
                    <Pagination
                      total={listDevices.paging.total}
                      pageSize={DEFAULT_LIMIT}
                      initPageNumber={listDevices.paging.pageNum}
                      onChange={(page) => {
                        searchParams.set('page', page.toString());
                        setSearchParams(searchParams);
                      }}
                      disable={listDevices.isLoading}
                    />
                  )}
                </>
              )}
            </RightColumn>
          </div>
        )}
      </div>
    </>
  );
};

export default DevicesListingPageView;
