import { Button, Form, Input } from 'antd';
import { AddIconNoOutline } from 'assets/icons';
import { Routes } from 'enums';
import { LeftColumn, PermissionWrapper, RightColumn, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useAdminPageController } from './AdminPageController';
import { useEffect, useRef } from 'react';
import useFlag from 'hooks/useFlag';
import { useLoaderContext } from 'context/LoaderContext';
import { eossDocLinks } from 'constant';

type FieldType = {
  searchText: string;
};

const AdminPage = () => {
  const { t } = useTranslation();

  const { loader } = useLoaderContext();

  const { form, refetchCountRef, handleSearch, handleResetSearch } = useAdminPageController();

  const [leftColumnOpen, markAsLeftColumnOpen, markAsLeftColumnClose] = useFlag(true);

  // const isShowCreateActions = useMatch(Routes.EAdminRoutes.LISTING);
  // 2024-02-09 Feedback: Create Action should be shown in both Listing and Detail page
  const isInListingPage = useMatch(Routes.EAdminRoutes.LISTING);
  const isInDetailPage = useMatch(Routes.EAdminRoutes.DETAILED);
  const canShowCreateAction =
    isInListingPage || (isInDetailPage && isInDetailPage.pathname != Routes.EAdminRoutes.CREATE); // check create because useMatch understands /admin/create as /admin/:adminUserId
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);
  const toolTipRef = useRef<{
    onHideTooltip: () => void;
    onShowTooltip: () => void;
  }>();

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    return () => {
      markAsLeftColumnOpen();
    };
  }, []);

  return (
    <div className="wrap-container overflow-hidden h-100">
      <div className="h-100 w-100 position-relative">
        <LeftColumn
          withCollapse
          leftColumnOpen={leftColumnOpen}
          onMouseEnter={() => toolTipRef.current?.onShowTooltip()}
          onMouseMove={() => toolTipRef.current?.onShowTooltip()}
          onMouseLeave={() => toolTipRef.current?.onHideTooltip()}
        >
          <Section
            title={t('adminUserPage.sections.search.title')}
            docLink={eossDocLinks.administrator.listing.searchAdministrator}
            classNameBody="p-4"
          >
            <Form
              id="adminPage_searchAction"
              form={form}
              layout="vertical"
              initialValues={{ customerId: null, groupName: '*' }}
              onFinish={(values) => {
                handleSearch(values.searchText);
              }}
              onFieldsChange={(changedFields) => {
                const searchTextField = changedFields.find(
                  (field) => field?.name.includes('searchText')
                );
                if (searchTextField && !searchTextField.value) {
                  handleResetSearch();
                }
              }}
            >
              <Form.Item<FieldType> name="searchText" className="mb-2">
                <Input
                  id="adminPage-leftColumn_searchInput"
                  type="search"
                  className="search-input"
                  allowClear
                />
              </Form.Item>
              <div className="fs-12 fw-normal text-black mt-2 mb-3">
                {t('adminUserPage.sections.search.inputDescription')}
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  id="adminPage-leftColumn_searchSubmitBtn"
                  className="btn-action btn-fill"
                  htmlType="submit"
                  onClick={() => {
                    const event = new CustomEvent('close-sidebar-action');
                    document.dispatchEvent(event);
                  }}
                >
                  <span className="fs-14 fw-medium">{t('components.search')}</span>
                </Button>
              </div>
            </Form>
          </Section>
          {canShowCreateAction && (
            <PermissionWrapper.Create>
              <Section
                title={t('adminUserPage.sections.create.title')}
                docLink={eossDocLinks.administrator.create}
                classNameBody="px-4 py-5"
              >
                <Button
                  id="adminPage_createAction"
                  onClick={() => {
                    loader.start();
                    navigate(Routes.EAdminRoutes.CREATE);
                  }}
                  className={
                    'btn-create w-100 d-flex justify-content-center align-items-center cursor-pointer'
                  }
                >
                  <div className="h-100 d-flex align-items-center">
                    <AddIconNoOutline color="var(--primary-1)" />
                  </div>
                  <div className="d-flex align-items-center fs-14 fw-semibold ms-2 h-100 btn-create-content">
                    {t('components.create')}
                  </div>
                </Button>
              </Section>
            </PermissionWrapper.Create>
          )}
        </LeftColumn>
        <RightColumn
          toolTipRef={toolTipRef}
          leftColumnOpen={leftColumnOpen}
          onSideBarClick={() => {
            leftColumnOpen ? markAsLeftColumnClose() : markAsLeftColumnOpen();
          }}
        >
          <Outlet context={{ refetchCount: refetchCountRef.current, scrollToTop }} />
        </RightColumn>
      </div>
    </div>
  );
};

export default AdminPage;
