import { Form, Input, Select } from 'antd';
import type { TAccount } from 'models';
import { useTranslation } from 'react-i18next';
import styles from './general.module.scss';
import { TAccountFieldType, TSelect } from '../AccountPageController';
import { DropdownIcon } from 'assets/icons';
import { FormInputTooltip, Section } from 'presentation/components';
import { memo } from 'react';
import ValidationUtilities from 'utils/validationUtils';
import { eossDocLinks } from 'constant';

type Props = {
  currentData?: TAccount;
  accountTypesData?: TSelect[];
  validationsData: TSelect[];
  domainsData?: TSelect[];
};

export const General = memo(
  ({ currentData, accountTypesData, validationsData, domainsData }: Props) => {
    const { t } = useTranslation();

    const editMode = !!currentData;

    const filterOption = (input: string, option?: { label: string; value: string | number }) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
      <Section
        contentId="customerPage_customerGeneralForm"
        title={t('accountPage.sections.general.title')}
        docLink={eossDocLinks.customer.update}
        classNameBody={`${styles.container} p-3`}
        allowCollapse
        expandedByDefault
        keyPanel="customer-general"
      >
        {editMode && (
          <>
            <Form.Item<TAccountFieldType> name="accountNumber" label={t('forms.accountId.label')}>
              <Input disabled />
            </Form.Item>
          </>
        )}
        <Form.Item<TAccountFieldType>
          label={t('forms.accountName.label')}
          name="name"
          rules={[
            { required: !editMode, message: t('forms.accountName.required') },
            {
              validator: (_, value: string) =>
                ValidationUtilities.fullNameValidationFn({
                  value,
                  errorMessage: t('forms.accountName.pattern')
                })
            }
          ]}
        >
          <Input disabled={editMode} />
        </Form.Item>
        <Form.Item<TAccountFieldType>
          name="invalidId"
          label={t('forms.valid.label')}
          colon={false}
          rules={[
            {
              required: true,
              message: t('forms.valid.required')
            }
          ]}
        >
          <Select
            filterOption={filterOption}
            optionFilterProp="children"
            options={validationsData}
            suffixIcon={<DropdownIcon />}
          />
        </Form.Item>
        <Form.Item<TAccountFieldType>
          label={t('forms.domain.label')}
          name="domainId"
          rules={[{ required: !editMode, message: t('forms.domain.required') }]}
        >
          <Select
            options={domainsData}
            showSearch
            filterOption={filterOption}
            optionFilterProp="children"
            suffixIcon={editMode ? null : <DropdownIcon />}
            disabled={editMode}
          />
        </Form.Item>
        <Form.Item<TAccountFieldType>
          label={t('forms.accountType.label')}
          name="accountTypeId"
          rules={[{ required: !editMode, message: t('forms.accountType.required') }]}
        >
          <Select
            defaultActiveFirstOption
            showSearch
            filterOption={filterOption}
            optionFilterProp="children"
            options={accountTypesData}
            suffixIcon={editMode ? null : <DropdownIcon />}
            disabled={editMode}
          />
        </Form.Item>
        <Form.Item<TAccountFieldType>
          label={t('forms.url.label')}
          name="url"
          rules={[
            {
              validator: (_, value: string) =>
                ValidationUtilities.urlValidationFn({
                  value,
                  errorMessage: t('forms.url.maxLength')
                })
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="address" label={t('forms.address.label')} className="mb-0">
          <div className="row px-3">
            <FormInputTooltip name="street" className="col-12 col-sm-6 p-0 pe-sm-2">
              <Form.Item<TAccountFieldType>
                name="street"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.streetValidationFn({
                        value,
                        errorMessage: t('forms.street.maxLength')
                      })
                  }
                ]}
              >
                <Input className={`${styles.addonData} text-truncate`} addonBefore="Street" />
              </Form.Item>
            </FormInputTooltip>
            <FormInputTooltip name="city" className="col-12 col-sm-6 p-0">
              <Form.Item<TAccountFieldType>
                name="city"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.cityValidationFn({
                        value,
                        errorMessage: t('forms.city.maxLength')
                      })
                  }
                ]}
              >
                <Input className={`${styles.addonData} text-truncate`} addonBefore="City" />
              </Form.Item>
            </FormInputTooltip>
            <FormInputTooltip name="state" className="col-12 col-sm-6 p-0 pe-sm-2">
              <Form.Item<TAccountFieldType>
                name="state"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.stateValidationFn({
                        value,
                        errorMessage: t('forms.state.maxLength')
                      })
                  }
                ]}
              >
                <Input className={`${styles.addonData} text-truncate`} addonBefore="State" />
              </Form.Item>
            </FormInputTooltip>
            <FormInputTooltip name="zip" className="col-12 col-sm-6 p-0">
              <Form.Item<TAccountFieldType>
                name="zip"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.zipValidationFn({
                        value,
                        errorMessage: t('forms.zip.maxLength')
                      })
                  }
                ]}
              >
                <Input className={`${styles.addonData} text-truncate`} addonBefore="Zip" />
              </Form.Item>
            </FormInputTooltip>
          </div>
        </Form.Item>
        <Form.Item<TAccountFieldType>
          name="comment"
          label={t('forms.comment.label')}
          className="mb-0"
          rules={[
            {
              validator: (_, value: string) =>
                ValidationUtilities.commentValidationFn({
                  value,
                  errorMessage: t('forms.comment.maxLength')
                })
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Section>
    );
  }
);
