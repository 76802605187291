import React, { useEffect, useRef } from 'react';
/**
 * Accepts a function that contains imperative, possibly effectful code.
 *
 * Build in React useEffect hook v18
 */
export const useSkipFirstEffect = (effect: React.EffectCallback, deps: React.DependencyList) => {
  const isFirstRenderRef = useRef<boolean>(true);
  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
    } else {
      effect();
    }
  }, deps);
};
