import { useEffect, useMemo, useState } from 'react';
import { throttle } from 'utils/common';
import {
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  SEMI_TABLET_BREAKPOINT,
  SEMI_DESKTOP_BREAKPOINT,
  DESKTOP_BREAKPOINT
} from 'constant';

const THROTTLE = 250;

const useResponsive = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < MOBILE_BREAKPOINT);

  const [isTablet, setIsTablet] = useState<boolean>(
    window.innerWidth >= MOBILE_BREAKPOINT && window.innerWidth < LAPTOP_BREAKPOINT
  );
  const [isSemiTablet, setIsSemiTablet] = useState<boolean>(
    window.innerWidth >= MOBILE_BREAKPOINT && window.innerWidth < SEMI_TABLET_BREAKPOINT
  );
  const [isSmallDesktop, setIsSmallDesktop] = useState<boolean>(
    window.innerWidth >= LAPTOP_BREAKPOINT && window.innerWidth < DESKTOP_BREAKPOINT
  );
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= LAPTOP_BREAKPOINT);
  const [isSemiDesktop, setIsSemiDesktop] = useState<boolean>(
    window.innerWidth >= SEMI_DESKTOP_BREAKPOINT
  );

  useEffect(() => {
    const throttledSetSize = throttle(
      () => {
        setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
        setIsTablet(
          window.innerWidth >= MOBILE_BREAKPOINT && window.innerWidth < LAPTOP_BREAKPOINT
        );
        setIsSemiTablet(
          window.innerWidth >= MOBILE_BREAKPOINT && window.innerWidth < SEMI_TABLET_BREAKPOINT
        );
        setIsDesktop(window.innerWidth >= LAPTOP_BREAKPOINT);
        setIsSmallDesktop(
          window.innerWidth >= LAPTOP_BREAKPOINT && window.innerWidth < DESKTOP_BREAKPOINT
        );
        setIsSemiDesktop(window.innerWidth >= SEMI_DESKTOP_BREAKPOINT);
      },
      THROTTLE,
      false
    );

    const onResize = () => {
      throttledSetSize();
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return useMemo(
    () => ({
      isMobile,
      isTablet,
      isSemiTablet,
      isSmallDesktop,
      isDesktop,
      isSemiDesktop
    }),
    [isMobile, isSmallDesktop, isTablet, isDesktop, isSemiDesktop]
  );
};

export default useResponsive;
