import { useCallback, useState } from 'react';
import type { TPaginationEOSS } from '../models';

export type AsyncState<T> = {
  data: T;
  loading: boolean;
  paging?: TPaginationEOSS;
};

export function useAsyncState<T extends AnyObject>(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  initialValue: T,
  paginationConfig?: TPaginationEOSS
) {
  const [state, setState] = useState<AsyncState<T>>({
    loading: false,
    data: initialValue,
    paging: paginationConfig
  });

  const startLoading = useCallback(() => {
    setState((prev) => ({
      ...prev,
      loading: true
    }));
  }, []);

  const stopLoading = useCallback(() => {
    setState((prev) => ({
      ...prev,
      loading: false
    }));
  }, []);

  return [state, setState, startLoading, stopLoading] as const;
}
