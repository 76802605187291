/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  COMMON_NAME_REGEX,
  DATETIME_FORMAT,
  EDocEntity,
  MAXIMUM_10_CHARACTERS,
  PASSWORD_VALIDATION_REGEX
} from 'constant';
import dayjs from 'dayjs';
import { EDeviceType, EServerType, ESortingDevicePage, EThermalCameraType } from 'enums';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import { TCameraBinding, TDevice, TLocalAuthProfile, TSortOrder } from 'models';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { FormInstance } from 'antd';
import { TFunction } from 'i18next/typescript/t';
import { Routes } from 'enums';
import { APIAction, ApiErrorResponse, unknownError } from 'models/ApiError';
import axios from 'axios';
import React from 'react';

dayjs.extend(RelativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

export function getError<T>(error: string) {
  const parseError = JSON.parse(error) as T;
  return parseError;
}

/**
 * Regex rules:
 * - Minimum twelve characters
 * - At least one uppercase letter
 * - At least one lowercase letter
 * - At least one number
 * - At least one of the following special characters: !, @, #, $, %, ^, &, *
 */
export const passwordValidate = (value: string) => PASSWORD_VALIDATION_REGEX.test(value);
export const domainNameValidate = (value: string) => COMMON_NAME_REGEX.test(value);

const getRndInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

const shuffle = (srt: string) => {
  const a = srt.split(','),
    n = a.length;
  for (let i = n - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }
  return a.join('');
};

//` ~ ! @ # $ % ^ & * ( ) - + { } [ ] | \ : ; ' " , . ? / < >
const specialChar = [
  33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 58, 59, 60, 62, 63, 64, 92, 93, 94,
  95, 96, 123, 124, 125, 126
];

export function generatePassword(length = MAXIMUM_10_CHARACTERS) {
  const p = Array.from({ length });
  const generatedPwd = shuffle(
    p.map(() => String.fromCharCode(getRndInteger(65, 90))).join() +
      p.map(() => String.fromCharCode(getRndInteger(97, 122))).join() +
      p.map(() => String.fromCharCode(getRndInteger(48, 57))).join() +
      p.map(() => String.fromCharCode(specialChar[getRndInteger(0, specialChar.length - 1)])).join()
  ).slice(0, length);

  if (!passwordValidate(generatedPwd)) {
    return generatePassword(length);
  }

  return generatedPwd;
}

export function mapSnakeCaseToCamelCase(data: any) {
  return Object.keys(data).reduce((acc: any, key: string) => {
    if (Array.isArray(data[key])) {
      Object.assign(acc, {
        [camelCase(key)]: data[key].map((item: any) => mapSnakeCaseToCamelCase(item))
      });
    } else if (typeof data[key] === 'object' && data[key] !== undefined && data[key] !== null) {
      Object.assign(acc, { [camelCase(key)]: mapSnakeCaseToCamelCase(data[key]) });
    } else {
      Object.assign(acc, { [camelCase(key)]: data[key] });
    }
    return acc;
  }, {});
}

export function mapCamelCaseToSnakeCase(data: any) {
  return Object.keys(data).reduce((acc: any, key: string) => {
    if (Array.isArray(data[key])) {
      Object.assign(acc, {
        [snakeCase(key)]: data[key].map((item: any) => mapCamelCaseToSnakeCase(item))
      });
    } else if (typeof data[key] === 'object' && data[key] !== undefined && data[key] !== null) {
      Object.assign(acc, { [snakeCase(key)]: mapCamelCaseToSnakeCase(data[key]) });
    } else {
      Object.assign(acc, { [snakeCase(key)]: data[key] });
    }
    return acc;
  }, {});
}

export const isJsonString = (str: string): boolean => {
  if (str === 'null' || str === '{}') return false;
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const checkDeviceStatus = (unixTimestamp: number) => {
  return dayjs.unix(unixTimestamp).isAfter(dayjs().add(-5, 'minutes'));
};

export const mapToServerType = (serverTypeName: string): EServerType => {
  switch (true) {
    case serverTypeName.includes('BVR'):
      return EServerType.BVR;
    case serverTypeName.includes('VAS'):
      return EServerType.VAS;
    case serverTypeName.includes('VRS'):
      return EServerType.VRS;
    default:
      return EServerType.BVR; // Default to BVR if name doesn't match any enum
  }
};

export const mapSortOrder = (order: TSortOrder): 'asc' | 'desc' | null => {
  if (order === 'ascend') return 'asc';
  if (order === 'descend') return 'desc';
  return null;
};

export const formatESTTime = (unixTimeStamp: number, timeZone?: string): string => {
  return dayjs.tz(dayjs.unix(unixTimeStamp), timeZone ?? 'EST').format(DATETIME_FORMAT);
};

export const checkFormIsValid = (form: FormInstance): boolean => {
  return !form.getFieldsError().some((field) => field.errors.length > 0);
};

export const scrollToFirstFieldError = (form: FormInstance) => {
  const firstErrorField = form.getFieldsError().filter((field) => field.errors.length > 0);
  if (firstErrorField.length <= 0) return;
  form.scrollToField(firstErrorField[0].name, {
    behavior: 'smooth',
    inline: 'center',
    block: 'center'
  });
};

export const getCopyMessageBasedOnPath = (
  pathName: string,
  t: TFunction<'en', undefined>
): string => {
  if (
    pathName.includes(Routes.EDevicesRoutes.INDEX) ||
    pathName.includes(Routes.EServerRoutes.INDEX)
  ) {
    return t('components.serialCopied');
  }

  if (pathName.includes(Routes.EAccountRoutes.LISTING)) {
    return t('components.accountNumberCopied');
  }

  if (pathName.includes(Routes.EAdminRoutes.LISTING)) {
    return t('components.loginCopied');
  }

  if (pathName.includes(Routes.EDomainRoutes.LISTING)) {
    return t('components.domainCopied');
  }

  if (pathName.includes(Routes.ECameraGroupRoutes.LISTING)) {
    return t('components.cameraGroupNameCopied');
  }

  if (pathName.includes(Routes.EUserRoutes.LISTING)) {
    return t('components.usernameCopied');
  }

  return t('components.copied');
};

export const getEntityFromMessage = ({
  message,
  t
}: {
  message?: string;
  t: TFunction<'en', undefined>;
}): string | undefined => {
  const entities = {
    [t('adminUserPage.entity')]: t('adminUserPage.entity'),
    Admin: t('adminUserPage.entity'), // For case Backend use Admin keyword in error message alternative for Administrator
    [t('domainPage.entity')]: t('domainPage.entity'),
    [t('accountPage.entity')]: t('accountPage.entity'),
    Account: t('accountPage.entity'), // For case Backend use Account keyword in error message alternative for Customer
    [t('cameraGroupPage.entity')]: t('cameraGroupPage.entity'), //check Device Group entity before check Device Entity
    [t('devicePage.entity')]: t('devicePage.entity'),
    [t('userPage.entity')]: t('userPage.entity'),
    [t('serverPage.entity')]: t('serverPage.entity')
  };

  for (const [entityText, entityValue] of Object.entries(entities)) {
    if (message?.includes(entityText)) {
      return entityValue;
    }
  }

  return undefined;
};

export const handleApiError = ({
  apiErrorResponse,
  t,
  entity,
  action,
  identifier,
  actionStr
}: {
  t: TFunction<'en', undefined>;
  entity: string;
  action: APIAction;
  apiErrorResponse: ApiErrorResponse;
  actionStr?: string;
  identifier?: string;
}): string => {
  const errorEntity = getEntityFromMessage({ message: apiErrorResponse.message, t: t });

  switch (apiErrorResponse.error) {
    case 'NOT_FOUND':
      return t(`errors.notFound.${action}`, {
        entity: entity,
        identifier: identifier
      });
    case 'RESOURCE_NOT_FOUND':
      return t('errors.resourceNotFound', {
        identifier: identifier
          ? identifier[0].toUpperCase() + identifier.slice(1).toLowerCase()
          : ''
      });
    case 'REPLACE_DEVICE_ITSELF':
      return t('errors.replaceDeviceItself');
    case 'MULTIPLE_RESOURCES_FOUND':
      return t('errors.multipleResources', {
        identifier: identifier
      });
    case 'Forbidden':
      return t(`errors.forbidden.${action}`, {
        entity: entity,
        action: actionStr
      });
    case 'DUPLICATE_RESOURCE':
      return t('errors.duplicateResource', {
        entity: entity,
        identifier: identifier
      });
    case 'Internal Server Error':
      return t('errors.internalServerError');
    case 'Bad Request':
      return t('errors.badRequest');
    case 'UNAUTHORIZED_ACCESS':
      return t('errors.unauthorizedAccess');
    case 'DISABLED_RESOURCE':
      return t('errors.disableResource', {
        entity: errorEntity ?? entity
      });
    case 'EXPIRED_TOKEN':
      return t('errors.expiredToken');
    case 'INVALID_TYPE':
      return t('errors.invalidAccountType');
    case 'USERNAME_OR_PASSWORD_INCORRECT':
      return t('errors.userNameOrPasswordIncorrect');
    case 'VALIDATION_ERROR':
      return t('errors.passwordInvalid');
    default:
      return t('errors.unknown');
  }
};

export function isApiErrorResponse(error: any): error is ApiErrorResponse {
  return error && typeof error.error === 'string' && typeof error.status === 'number';
}

export function convertAxiosErrorToApiError(error: unknown): ApiErrorResponse {
  if (axios.isAxiosError(error)) {
    return error.response ? error.response.data : unknownError;
  }

  // for local api error: like server notfound
  return isApiErrorResponse(error) ? error : unknownError;
}

export const parseLocalUser = (input: string | null) => {
  try {
    return JSON.parse(input || '{}') as TLocalAuthProfile;
  } catch (error) {
    console.debug('cannot parse ', input, { error });
    return null;
  }
};

/**
 * This is a utility function to convert bytes per second to Megabits per second.
 * @param {number} bps The value in bytes per second to be converted to Megabits per second.
 * @returns {number} The result will be rounded to 3 decimal digits.
 */
export function convertBytePerSecondToMbps(bps: number) {
  const temp = Number(((bps * 8) / (1024 * 1024)).toFixed(3));
  const ceilTemp = Math.ceil(temp);
  if (ceilTemp === temp) {
    return temp.toString();
  }
  return ceilTemp.toString();
}

/**
 * This is a utility function to check a value is valid (not equal null and undefined).
 * @param value {any} The param in any value type.
 * @returns {boolean} The result will be a boolean value.
 */
export function isValidValue(value: any) {
  return value !== undefined && value !== null;
}

/**
 * This function checks if a given value is a valid string.
 * A valid string is not null, not undefined, and not an empty string after trimming leading and trailing spaces.
 * This function return true if the value is a number or a valid string
 *
 * @param value - The value to be checked.
 * @returns A boolean indicating whether the value is a valid string.
 */
export function isValidStringOrNumber(value?: string | number | null) {
  if (isValidValue(value)) {
    return `${value}`.trim().length > 0;
  }
  return false;
}

/**
 * This is a utility function to check a value is number or not
 * @param value {any} The param in any value type.
 * @returns {boolean} The result will be a boolean value.
 */
export function isNumber(value: any) {
  return isValidValue(value) && !Number.isNaN(Number(value));
}

export function secondaryBVRServerDisplay({
  secondaryBvrServerIp,
  tertiaryBvrServerIp,
  bvrConnectedIp
}: {
  secondaryBvrServerIp?: string;
  tertiaryBvrServerIp?: string;
  bvrConnectedIp: string | null;
}): string | undefined {
  if (bvrConnectedIp && bvrConnectedIp === tertiaryBvrServerIp) {
    return tertiaryBvrServerIp;
  }
  return secondaryBvrServerIp;
}

export function isOpticalCameraInThermalOpticalDevice({
  deviceTypeName,
  cameraType
}: {
  deviceTypeName: string;
  cameraType: string;
}) {
  return (
    (deviceTypeName ?? '').toLowerCase() === EDeviceType.THERMAL_OPTICAL_CAMERA.toLowerCase() &&
    (cameraType ?? '').toLowerCase() === EThermalCameraType.OPTICAL.toLowerCase()
  );
}

export function isThermalCameraInThermalOpticalDevice({
  deviceTypeName,
  cameraType
}: {
  deviceTypeName: string;
  cameraType: string;
}) {
  return (
    (deviceTypeName ?? '').toLowerCase() === EDeviceType.THERMAL_OPTICAL_CAMERA.toLowerCase() &&
    (cameraType ?? '').toLowerCase() === EThermalCameraType.THERMAL.toLowerCase()
  );
}

export function convertToPascalCase(value: string): string {
  return value.length > 0 ? value[0].toUpperCase() + value.slice(1).toLowerCase() : value;
}

export function splitLastContact(dateTime?: number): { date: string; time: string } {
  if (dateTime) {
    const dateTimeString = dayjs.unix(dateTime).format(DATETIME_FORMAT);
    if (dateTimeString) {
      const dateTimeSplitArray = dateTimeString.split(', ');
      if (dateTimeSplitArray[0] && dateTimeSplitArray[1]) {
        return {
          date: dateTimeSplitArray[0] + ', ',
          time: dateTimeSplitArray[1]
        };
      }
    }
  }
  return {
    date: '',
    time: ''
  };
}

/**
 * This function will be replace all space, tab character (\t) or new line character (\n)
 * @param text Unicode string
 * @returns New string does not contain any space, tab or new line character
 */
export function replaceAllSpace(text: string): string {
  return text.replaceAll(/[ \t\n]/g, '');
}

/**
 * Debounces a function to prevent it from being called too frequently.
 *
 * @template F - The type of the function to debounce.
 * @param fn - The function to debounce.
 * @param ms - The debounce delay in milliseconds.
 * @param shouldRunFirst - Whether the debounced function should run immediately when invoked.
 * @param shouldRunLast - Whether the debounced function should run only when the debounce delay has passed.
 * @returns A debounced version of the input function.
 */
export function debounce<F extends (...args: any[]) => void>(
  fn: F,
  ms: number,
  shouldRunFirst = true,
  shouldRunLast = true
) {
  let waitingTimeout: number | undefined;

  return (...args: Parameters<F>) => {
    if (waitingTimeout) {
      clearTimeout(waitingTimeout);
      waitingTimeout = undefined;
    } else if (shouldRunFirst) {
      fn(...args);
    }

    waitingTimeout = self.setTimeout(() => {
      if (shouldRunLast) {
        fn(...args);
      }

      waitingTimeout = undefined;
    }, ms);
  };
}

/**
 * Throttles a function to prevent it from being called too frequently.
 *
 * @template F - The type of the function to throttle.
 * @param fn - The function to throttle.
 * @param ms - The throttle delay in milliseconds.
 * @param shouldRunFirst - Whether the throttled function should run immediately when invoked.
 * @returns A throttled version of the input function.
 */
export function throttle<F extends (...args: any[]) => void>(
  fn: F,
  ms: number,
  shouldRunFirst = true
) {
  let interval: number | undefined;
  let isPending: boolean;
  let args: Parameters<F>;

  return (..._args: Parameters<F>) => {
    isPending = true;
    args = _args;

    if (!interval) {
      if (shouldRunFirst) {
        isPending = true;
        (fn as (...args: any[]) => any)(...args);
      }

      interval = self.setInterval(() => {
        if (!isPending) {
          self.clearInterval(interval);
          interval = undefined;
          return;
        }

        isPending = false;
        fn(...args);
      }, ms);
    }
  };
}

/**
 * Parses a number in E notation (e.g., 1.23e+4) to a string, handling negative E notation.
 * If the number has a negative E notation, it splits the number into the prefix and suffix,
 * and formats the suffix to the number of decimal places specified by the suffix.
 *
 * @param number - The number to be parsed.
 * @returns The parsed number as a string.
 */
export function parseENotationNumberToString(number?: number): string {
  if (number === undefined || number === null || Number.isNaN(number)) return '';
  const numStr = number.toString();
  if (!numStr.includes('e-') || numStr.trim().length === 0) {
    return numStr;
  }

  const [prefix, suffix] = number.toString().split('e-');
  const absPrefix = Math.abs(Number(prefix)).toString().replace('.', '');

  if (Number.isNaN(Number(suffix))) {
    return numStr;
  }
  const zeros = '0'.repeat(Number(suffix) - 1);
  const result = `0.${zeros}${absPrefix}`;
  return number < 0 ? `-${result}` : result;
}

export function concatCameraNamePrefix({
  cameraName,
  serialNumber,
  isOpticalCamera,
  isDualSensor
}: {
  cameraName?: string;
  serialNumber: string;
  isOpticalCamera?: boolean;
  isDualSensor?: boolean;
}) {
  const serialLength = serialNumber.length;
  const prefix = `${serialNumber.slice(Math.max(0, serialLength - 6), serialLength)}${
    isDualSensor ? (isOpticalCamera ? '_O' : '_T') : ''
  }`;
  const colon = ': ';
  const CAMERA_DEFAULT_NAME = 'CAM1';
  const OPTICAL_CAMERA_DEFAULT_NAME = CAMERA_DEFAULT_NAME;
  const THERMAL_CAMERA_DEFAULT_NAME = 'CAM2';
  const trimmedCameraName = cameraName?.trim();
  // If a camera name is provided, return it with the serial number prefix and colon separator.
  // Note: camera name is trimmed.
  if (trimmedCameraName?.length) {
    return `${prefix}${colon}${trimmedCameraName}`;
  }

  // ERRP-189: Special handling if the camera name is empty
  // Case 1: Dual Sensor Camera
  // - Show "123456_O: CAM1" for Optical
  // - Show "123456_T: CAM2" for Thermal
  if (isDualSensor) {
    return `${prefix}${colon}${
      isOpticalCamera ? OPTICAL_CAMERA_DEFAULT_NAME : THERMAL_CAMERA_DEFAULT_NAME
    }`;
  }

  // Case 2: Single Camera
  // - Show "123456: CAM1" for single camera with default name
  return `${prefix}${colon}${CAMERA_DEFAULT_NAME}`;
}

export function convertToValidString({
  value,
  defaultValue = '-'
}: {
  value?: any;
  defaultValue?: string;
}) {
  if (isValidValue(value)) {
    return value.toString().length > 0 ? value : defaultValue;
  }
  return '-';
}

export function concatURLSearchParams(arg: { [key: string]: string | null }) {
  const searchParams = new URLSearchParams();
  Object.entries(arg).forEach(([key, value]) => {
    if (value !== null) {
      searchParams.append(key, value);
    }
  });
  const result = searchParams.toString();
  return result.length > 0 ? `?${result}` : '';
}

export function isTokenExpired(tokenExpiredTime: string | null) {
  // No token -> Expired
  if (tokenExpiredTime === null) return true;
  const tokenExpiredTimeNumber = Number(tokenExpiredTime);
  // Not a number -> Expired
  if (Number.isNaN(tokenExpiredTimeNumber)) return true;
  const currentTime = Math.floor(Date.now() / 1000); // seconds
  // Token Time < Current Time -> Expired
  return dayjs.unix(tokenExpiredTimeNumber).isBefore(currentTime);
}

export function getCountdownTime(tokenExpiredTime: string | null) {
  if (tokenExpiredTime === null) return undefined;
  const tokenExpiredTimeNumber = Number(tokenExpiredTime);
  if (Number.isNaN(tokenExpiredTimeNumber)) return undefined;
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime < tokenExpiredTimeNumber ? tokenExpiredTimeNumber - currentTime : undefined;
}

export function isNotDeviceCamera(device: TDevice | null) {
  return (
    device?.typeName.toUpperCase() === EDeviceType.ASTRO_CAMERA ||
    device?.typeName.toUpperCase() === EDeviceType.UPS_MONITOR
  );
}

export function getDeviceGroupBinding(device: TDevice) {
  if (
    ![
      `${EDeviceType.IP_CAMERA}`,
      `${EDeviceType.THERMAL_CAMERA}`,
      `${EDeviceType.THERMAL_OPTICAL_CAMERA}`
    ].includes(device?.typeName)
  ) {
    return [];
  }

  const getOpticalAndThermalCameras = (
    cameras: Array<{
      cameraTypeName?: string;
      name?: string;
      groups?: Array<{ id: number; name: string }>;
    }>
  ) => {
    const opticalCamera = cameras?.find(
      (camera) =>
        (camera.cameraTypeName ?? '').toLowerCase() === EThermalCameraType.OPTICAL.toLowerCase()
    );
    const thermalCamera = cameras?.find(
      (camera) =>
        (camera.cameraTypeName ?? '').toLowerCase() === EThermalCameraType.THERMAL.toLowerCase()
    );
    return { opticalCamera, thermalCamera };
  };

  const getUniqueGroups = (groups: Array<{ id: number; name: string }>) => {
    const groupMap = new Map<number, { id: number; name: string }>();
    groups.forEach((group) => {
      if (!groupMap.has(group.id)) {
        groupMap.set(group.id, group);
      }
    });
    return Array.from(groupMap.values());
  };

  const getCameraNames = (
    group: { id: number; name: string },
    opticalCamera?: { name?: string; groups?: Array<{ id: number; name: string }> },
    thermalCamera?: { name?: string; groups?: Array<{ id: number; name: string }> }
  ) => {
    const cameraNames = [];
    if (opticalCamera?.groups?.some((opticalGroup) => opticalGroup?.id === group?.id)) {
      cameraNames.push(opticalCamera.name ?? '');
    }
    if (thermalCamera?.groups?.some((thermalGroup) => thermalGroup?.id === group?.id)) {
      cameraNames.push(thermalCamera?.name ?? '');
    }
    return cameraNames;
  };

  if (device?.cameras && device.cameras.length > 0) {
    const { opticalCamera, thermalCamera } = getOpticalAndThermalCameras(device.cameras);

    const uniqueGroups = getUniqueGroups([
      ...(opticalCamera?.groups || []),
      ...(thermalCamera?.groups || [])
    ]);

    return uniqueGroups.map((group) => ({
      ...group,
      cameraNames: getCameraNames(group, opticalCamera, thermalCamera)
    }));
  }

  return [];
}

export function sortDeviceBinding({
  cameraBinding,
  sortKey
}: {
  cameraBinding: (TCameraBinding & { key: string })[];
  sortKey: ESortingDevicePage;
}) {
  switch (sortKey) {
    case ESortingDevicePage.NO_SORT: {
      return cameraBinding.map((camera: TCameraBinding) => ({
        ...camera,
        key: camera.id.toString()
      }));
    }
    case ESortingDevicePage.ORDER_ASC_BY_CAMERA_NAME: {
      return cameraBinding.sort(
        (a: TCameraBinding & { key: string }, b: TCameraBinding & { key: string }) => {
          const prefixA = concatCameraNamePrefix({
            serialNumber: a?.serialNumber,
            cameraName: a?.name,
            isDualSensor: a?.deviceTypeName === EDeviceType.THERMAL_OPTICAL_CAMERA,
            isOpticalCamera: (a?.cameraType ?? '').toLowerCase() === EThermalCameraType.OPTICAL
          });
          const prefixB = concatCameraNamePrefix({
            serialNumber: b?.serialNumber,
            cameraName: b?.name,
            isDualSensor: b?.deviceTypeName === EDeviceType.THERMAL_OPTICAL_CAMERA,
            isOpticalCamera: (b?.cameraType ?? '').toLowerCase() === EThermalCameraType.OPTICAL
          });
          const aNameWithSerialPrefix = `${prefixA}${a?.name}`;
          const bNameWithSerialPrefix = `${prefixB}${b?.name}`;
          return aNameWithSerialPrefix.localeCompare(bNameWithSerialPrefix);
        }
      );
    }
    case ESortingDevicePage.ORDER_DESC_BY_CAMERA_NAME: {
      return cameraBinding.sort(
        (a: TCameraBinding & { key: string }, b: TCameraBinding & { key: string }) => {
          const prefixA = concatCameraNamePrefix({
            serialNumber: a?.serialNumber,
            cameraName: a?.name,
            isDualSensor: a?.deviceTypeName === EDeviceType.THERMAL_OPTICAL_CAMERA,
            isOpticalCamera: (a?.cameraType ?? '').toLowerCase() === EThermalCameraType.OPTICAL
          });
          const prefixB = concatCameraNamePrefix({
            serialNumber: b?.serialNumber,
            cameraName: b?.name,
            isDualSensor: b?.deviceTypeName === EDeviceType.THERMAL_OPTICAL_CAMERA,
            isOpticalCamera: (b?.cameraType ?? '').toLowerCase() === EThermalCameraType.OPTICAL
          });
          const aNameWithSerialPrefix = `${prefixA}${a?.name}`;
          const bNameWithSerialPrefix = `${prefixB}${b?.name}`;
          return bNameWithSerialPrefix.localeCompare(aNameWithSerialPrefix);
        }
      );
    }
    case ESortingDevicePage.ORDER_ASC_BY_SERIAL_NUMBER: {
      return cameraBinding.sort(
        (a: TCameraBinding & { key: string }, b: TCameraBinding & { key: string }) =>
          a.serialNumber.localeCompare(b.serialNumber)
      );
    }
    case ESortingDevicePage.ORDER_DESC_BY_SERIAL_NUMBER: {
      return cameraBinding.sort(
        (a: TCameraBinding & { key: string }, b: TCameraBinding & { key: string }) =>
          b.serialNumber.localeCompare(a.serialNumber)
      );
    }
  }
}

export function isValidNumber(number: any) {
  return isValidValue(number) && !Number.isNaN(Number(number));
}

export const getDocsRouterName = (crumb: string, t: TFunction<'en', undefined>): string | null => {
  switch (crumb) {
    case t('adminUserPage.breadcrumb.listing'):
      return EDocEntity.ADMINISTRATOR;
    case t('domainPage.breadcrumb.listing'):
      return EDocEntity.DOMAIN;
    case t('accountPage.breadcrumb.listing'):
      return EDocEntity.CUSTOMER;
    case t('devicePage.breadcrumb.listing'):
      return EDocEntity.DEVICE;
    case t('cameraGroupPage.breadcrumb.listing'):
      return EDocEntity.DEVICE_GROUP;
    case t('userPage.breadcrumb.listing'):
      return EDocEntity.EYEVIEW_USER;
    default:
      return null;
  }
};

export function mapSnakeCaseToCamelCaseV2<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map((item) => mapSnakeCaseToCamelCaseV2(item)) as T;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.entries(obj).reduce(
      (acc, [key, value]) => {
        const camelKey = camelCase(key);
        acc[camelKey] = mapSnakeCaseToCamelCaseV2(value);
        return acc;
      },
      {} as Record<string, any>
    ) as T;
  }
  return obj;
}

export function mapCamelCaseToSnakeCaseV2<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map((item) => mapCamelCaseToSnakeCaseV2(item)) as T;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.entries(obj).reduce(
      (acc, [key, value]) => {
        const camelKey = snakeCase(key);
        acc[camelKey] = mapCamelCaseToSnakeCaseV2(value);
        return acc;
      },
      {} as Record<string, any>
    ) as T;
  }
  return obj;
}

export function isValidDependencies(values: React.DependencyList) {
  return values.every((value) => {
    if (value === undefined || value === null) return false;

    if (typeof value === 'number') return isValidNumber(value);
    if (typeof value === 'string') return isValidValue(value) && value.length > 0;
    if (typeof value === 'boolean') return value;
    if (Array.isArray(value)) return value.length > 0;
    if (typeof value === 'object') return Object.keys(value).length > 0;

    return true;
  });
}
