import { useDeviceDetailPageController } from './DeviceDetailPageController';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormAction, Section } from 'presentation/components';
import { Result, Switch, Tooltip } from 'antd';
import {
  EAdminTypeDisplayName,
  ERoleKey,
  EStorageKey,
  ESubFeatureKey,
  ESwitchTriggerDeviceBindingRefresh,
  Routes
} from 'enums';
import TableDeviceStatus from './components/TableDeviceStatus';
import { useTranslation } from 'react-i18next';
import styles from './deviceDetailPage.module.scss';
import FormDeviceInformation from './components/FormDeviceInformation';
import FormSystemRuntime from './components/FormSystemRuntime';
import { usePathPermission } from 'hooks/usePermission';
import { checkDeviceStatus, isValidStringOrNumber, parseLocalUser } from 'utils/common';
import FormBARCStats from './components/FormBARCStats';
import TableCameraGroup from './components/TableCameraGroup';
import { TDeviceType } from 'models';
import React from 'react';
import { eossDocLinks } from 'constant';

const SWITCH_FIXED_WIDTH = 120;

function DeviceDetailPageView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    isInEdit,
    warningMessage,
    intervalLoading,
    isIPCamera,
    isAstro,
    isThermalCamera,
    isThermalOpticalCamera,
    isUPSMonitor,
    isOpticalRecording,
    isThermalRecording,
    isOpticalOnline,
    isThermalOnline,
    connectedBVR,
    currentDevice,
    listServicePackage,
    formInformation,
    camerasSelectedRow,
    listDeviceType,
    listAssignedCameraGroups,
    listAccountsService,
    listServerBVR,
    bindGroupAction,
    isAccountInvalid,
    otherDeviceGroupsAssignment,
    onListIdsUnassignChange,
    onSubmit,
    onCamerasGroupBindingSelectedChange,
    onInEditChange
  } = useDeviceDetailPageController();
  const { t } = useTranslation();
  if (!currentDevice) {
    return (
      <Result
        status="404"
        title={t('devicePage.404.title')}
        subTitle={t('devicePage.404.subTitle')}
      />
    );
  }

  // Display BARC Stats Section when one of those fields is valid (is not null & is not undefined)
  const isShowBARCStats =
    isValidStringOrNumber(currentDevice?.data?.status) ||
    isValidStringOrNumber(currentDevice?.data?.curThrpt) ||
    isValidStringOrNumber(currentDevice?.data?.maxThrpt) ||
    isValidStringOrNumber(currentDevice?.data?.minThrpt) ||
    isValidStringOrNumber(currentDevice?.data?.bufferSz) ||
    isValidStringOrNumber(currentDevice?.data?.barcFrame);

  // Display System Runtime Stats Section when one of those fields is valid (is not null & is not undefined)
  const isShowSystemRuntimeStats =
    isValidStringOrNumber(currentDevice?.systemData?.cpuUtil) ||
    isValidStringOrNumber(currentDevice?.systemData?.memUtil) ||
    isValidStringOrNumber(currentDevice?.systemData?.sdUtil) ||
    isValidStringOrNumber(currentDevice?.systemData?.cpuTemp) ||
    isValidStringOrNumber(currentDevice?.kernel);

  const getDeviceTypeByNameFn = (name?: string) => {
    if (name && name.length > 0) {
      return (
        listDeviceType.find(
          (value: TDeviceType) => value?.name.toLowerCase() === name.toLowerCase()
        )?.displayName ?? ''
      );
    }
    return '';
  };

  const isAllowBindCameraGroup = usePathPermission(
    Routes.ECameraGroupRoutes.LISTING,
    ERoleKey.UPDATE,
    ESubFeatureKey.ASSIGNMENT
  ).allowed;

  const isAllowSubmit =
    usePathPermission(Routes.EDevicesRoutes.LISTING, ERoleKey.UPDATE, ESubFeatureKey.BASIC)
      .allowed || isAllowBindCameraGroup;

  const currentProfile = parseLocalUser(localStorage.getItem(EStorageKey.EOSS_CURRENT_USER));
  const isDomainCustomerAdmin =
    currentProfile?.adminTypeDisplayName.includes(EAdminTypeDisplayName.DOMAIN_ADMIN) ?? false;
  const isSystemAdmin =
    currentProfile?.adminTypeDisplayName.includes(EAdminTypeDisplayName.SYSTEM_ADMIN) ?? false;

  return (
    <div className={`animate__animated animate__fadeIn animate__faster me-2 ${styles.container}`}>
      <Section
        title={`${currentDevice?.typeName} ${
          isUPSMonitor
            ? t('devicePage.sections.otherDeviceStatus.title', {
                deviceType: t('devicePage.UPSEntity'),
                serialNumber: currentDevice.serialNumber
              })
            : t('devicePage.sections.deviceStatus.title', {
                deviceType: getDeviceTypeByNameFn(currentDevice.typeName),
                serialNumber: currentDevice.serialNumber
              })
        }`}
        docLink={eossDocLinks.device.details.overViewSection}
        keyPanel={'deviceStatus'}
        allowCollapse
        expandedByDefault
      >
        <TableDeviceStatus
          listDevice={[currentDevice]}
          isIPCamera={isIPCamera}
          isThermalCamera={isThermalCamera}
          isThermalOpticalCamera={isThermalOpticalCamera}
          isUPSMonitor={isUPSMonitor}
          isAstroDevice={isAstro}
          isDomainCustomerAdmin={isDomainCustomerAdmin}
        />
      </Section>
      <div className="row">
        <div
          className={`col-12 col-lg-7 col-slg-8 col-md-9 ${
            !(
              (isShowSystemRuntimeStats ||
                (isShowBARCStats && (isIPCamera || isThermalCamera || isThermalOpticalCamera))) &&
              isSystemAdmin
            )
              ? styles.stretchColumn
              : ''
          }`}
        >
          <Section
            title={t('devicePage.sections.deviceInfo.title')}
            docLink={eossDocLinks.device.details.informationSection}
            classNameBody="px-4 pt-3 pb-4"
            keyPanel="deviceInfo"
            allowCollapse
            expandedByDefault
            warningMessage={
              isAccountInvalid ? t('devicePage.sections.deviceService.warningMessageInvalid') : ''
            }
          >
            <FormDeviceInformation
              form={formInformation}
              deviceType={currentDevice?.typeName}
              listAccounts={listAccountsService}
              listServicesPackage={listServicePackage}
              isIPCamera={isIPCamera}
              isThermalCamera={isThermalCamera}
              isThermalOpticalCamera={isThermalOpticalCamera}
              isAstro={isAstro}
              isUPSMonitor={isUPSMonitor}
              isDomainCustomerAdmin={isDomainCustomerAdmin}
              isOpticalRecording={isOpticalRecording}
              isThermalRecording={isThermalRecording}
              isOpticalOnline={isOpticalOnline}
              isThermalOnline={isThermalOnline}
              listServerBVR={listServerBVR.filter(
                (server) =>
                  checkDeviceStatus(server?.lastContact) || connectedBVR.includes(server?.id)
              )}
              onSubmit={onSubmit}
            />
          </Section>
          {/**
           * Display this section when screen width is between 1000px and 1280px
           */}
          {(isIPCamera || isThermalCamera || isThermalOpticalCamera) && (
            <div className={styles.hideInSmallDesktop}>
              <Section
                title={
                  <div className="d-flex align-items-center gap-2">
                    {t('devicePage.sections.cameraGroupBinding.title')}
                    <Tooltip
                      title={
                        isInEdit
                          ? t('cameraGroupPage.sections.autoRefresh.tooltip.editMode')
                          : t('cameraGroupPage.sections.autoRefresh.tooltip.viewMode')
                      }
                      trigger="hover"
                      destroyTooltipOnHide
                    >
                      <Switch
                        value={isInEdit}
                        checkedChildren={ESwitchTriggerDeviceBindingRefresh.IN_EDIT}
                        unCheckedChildren={ESwitchTriggerDeviceBindingRefresh.DATA_VIEW}
                        defaultChecked
                        style={{
                          width: SWITCH_FIXED_WIDTH
                        }}
                        onChange={onInEditChange}
                        onClick={(_, e) => {
                          e.stopPropagation();
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                keyPanel={'cameraGroupBinding'}
                allowCollapse
                expandedByDefault
              >
                {warningMessage && (
                  <div
                    className="fs-12 rounded-4 px-2"
                    style={{ backgroundColor: 'var(--sub-1)', color: 'var(--sub-3)' }}
                  >
                    <span className="fw-medium ms-2">Warning: </span>
                    <span>{warningMessage}</span>
                  </div>
                )}
                <TableCameraGroup
                  disabled={!isAllowBindCameraGroup}
                  loading={bindGroupAction.isLoading || listAssignedCameraGroups.loading}
                  serialNumber={currentDevice?.serialNumber ?? ''}
                  listCameraGroup={listAssignedCameraGroups.data}
                  camerasSelectedRow={camerasSelectedRow}
                  onCamerasGroupBindingSelectedChange={onCamerasGroupBindingSelectedChange}
                  onUnassignCameraGroup={(keys) => {
                    onListIdsUnassignChange(keys);
                  }}
                  isThermalOpticalCamera={isThermalOpticalCamera}
                />
              </Section>
            </div>
          )}

          {/**
           * Display this section when screen width is between 1000px and 1280px
           */}
          {(isAstro || isUPSMonitor) && (
            <div className={styles.hideInSmallDesktop}>
              <Section
                title={t('devicePage.sections.cameraGroupBinding.title')}
                keyPanel={'otherCameraGroupBinding'}
                allowCollapse
                expandedByDefault
              >
                {warningMessage && (
                  <div
                    className="fs-12 rounded-4 px-2"
                    style={{ backgroundColor: 'var(--sub-1)', color: 'var(--sub-3)' }}
                  >
                    <span className="fw-medium ms-2">Warning: </span>
                    <span>{warningMessage}</span>
                  </div>
                )}
                <TableCameraGroup
                  disabled={true}
                  loading={otherDeviceGroupsAssignment.loading}
                  listCameraGroup={otherDeviceGroupsAssignment.data}
                  camerasSelectedRow={
                    otherDeviceGroupsAssignment.data.length > 0
                      ? [otherDeviceGroupsAssignment.data[0].id]
                      : []
                  }
                  onCamerasGroupBindingSelectedChange={() => {}}
                  onUnassignCameraGroup={() => {}}
                  isThermalOpticalCamera={false}
                  serialNumber={''}
                />
              </Section>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-5 col-slg-4 col-md-3">
          {isShowSystemRuntimeStats && isSystemAdmin && (
            <Section
              title={t('devicePage.sections.deviceSystemRuntime.title')}
              docLink={eossDocLinks.device.details.systemRuntimeSection}
              classNameBody="px-4 pt-3 pb-4"
              className={`${
                !(isShowBARCStats && (isIPCamera || isThermalCamera || isThermalOpticalCamera))
                  ? 'w-100'
                  : ''
              }`}
              keyPanel="deviceInfo"
              allowCollapse
            >
              <FormSystemRuntime currentDevice={currentDevice} loading={intervalLoading} />
            </Section>
          )}
          {isShowBARCStats &&
            (isIPCamera || isThermalCamera || isThermalOpticalCamera) &&
            isSystemAdmin &&
            !!Object.keys(currentDevice.data).length && (
              <Section
                title={t('devicePage.sections.barcStats.title')}
                docLink={eossDocLinks.device.details.statsSection}
                allowCollapse
                classNameBody="px-4 pt-3 pb-4"
                className={`${!isShowSystemRuntimeStats ? 'w-100' : ''}`}
                keyPanel={'barc-stats'}
              >
                <FormBARCStats currentDevice={currentDevice} loading={intervalLoading} />
              </Section>
            )}

          {/**
           * Display this section when screen width is larger 1280px
           */}
          {(isIPCamera || isThermalCamera || isThermalOpticalCamera) && (
            <div className={styles.showInSmallDesktop}>
              <Section
                docLink={eossDocLinks.device.details.groupBindingSection}
                title={
                  <div className="d-flex align-items-center gap-2">
                    {t('devicePage.sections.cameraGroupBinding.title')}
                    <Tooltip
                      title={
                        isInEdit
                          ? t('cameraGroupPage.sections.autoRefresh.tooltip.editMode')
                          : t('cameraGroupPage.sections.autoRefresh.tooltip.viewMode')
                      }
                      trigger="hover"
                      destroyTooltipOnHide
                    >
                      <Switch
                        value={isInEdit}
                        checkedChildren={ESwitchTriggerDeviceBindingRefresh.IN_EDIT}
                        unCheckedChildren={ESwitchTriggerDeviceBindingRefresh.DATA_VIEW}
                        defaultChecked
                        style={{
                          width: SWITCH_FIXED_WIDTH
                        }}
                        onChange={onInEditChange}
                        onClick={(_, e) => {
                          e.stopPropagation();
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                keyPanel={'cameraGroupBinding'}
                allowCollapse
                expandedByDefault
              >
                {warningMessage && (
                  <div
                    className="fs-12 rounded-4 px-2"
                    style={{ backgroundColor: 'var(--sub-1)', color: 'var(--sub-3)' }}
                  >
                    <span className="fw-medium ms-2">Warning: </span>
                    <span>{warningMessage}</span>
                  </div>
                )}
                <TableCameraGroup
                  disabled={!isAllowBindCameraGroup}
                  loading={bindGroupAction.isLoading || listAssignedCameraGroups.loading}
                  serialNumber={currentDevice?.serialNumber ?? ''}
                  listCameraGroup={listAssignedCameraGroups.data}
                  camerasSelectedRow={camerasSelectedRow}
                  onCamerasGroupBindingSelectedChange={onCamerasGroupBindingSelectedChange}
                  onUnassignCameraGroup={(keys) => {
                    onListIdsUnassignChange(keys);
                  }}
                  isThermalOpticalCamera={isThermalOpticalCamera}
                />
              </Section>
            </div>
          )}

          {/**
           * Display this section when screen width is larger 1280px
           */}
          {(isAstro || isUPSMonitor) && (
            <div className={styles.showInSmallDesktop}>
              <Section
                title={t('devicePage.sections.cameraGroupBinding.title')}
                keyPanel={'otherCameraGroupBinding'}
                allowCollapse
                expandedByDefault
              >
                {warningMessage && (
                  <div
                    className="fs-12 rounded-4 px-2"
                    style={{ backgroundColor: 'var(--sub-1)', color: 'var(--sub-3)' }}
                  >
                    <span className="fw-medium ms-2">Warning: </span>
                    <span>{warningMessage}</span>
                  </div>
                )}
                <TableCameraGroup
                  disabled={true}
                  loading={otherDeviceGroupsAssignment.loading}
                  listCameraGroup={otherDeviceGroupsAssignment.data}
                  camerasSelectedRow={
                    otherDeviceGroupsAssignment.data.length > 0
                      ? [otherDeviceGroupsAssignment.data[0].id]
                      : []
                  }
                  onCamerasGroupBindingSelectedChange={() => {}}
                  onUnassignCameraGroup={() => {}}
                  isThermalOpticalCamera={false}
                  serialNumber={''}
                />
              </Section>
            </div>
          )}

          {/* {isAstro && !isDomainCustomerAdmin && (
            <Section
              title={t('devicePage.sections.jetsonStats.title')}
              allowCollapse
              keyPanel={'jetson-stats'}
            >
              <TableJetsonStats
                fieldDisplayNameMappings={fieldDisplayNameMappings ?? {}}
                data={
                  currentDevice.data || {
                    modelVersion: '-',
                    hwSn: '-',
                    ram: '-',
                    camModule: '-',
                    gpuUtil: '-',
                    queueSz: '-'
                  }
                }
              />
            </Section>
          )} */}
        </div>
      </div>

      <FormAction
        onCancel={() => {
          navigate(
            {
              pathname: `${Routes.EDevicesRoutes.INDEX}/${(
                currentDevice.typeName ?? ''
              ).toLowerCase()}`,
              search: searchParams.toString()
            },
            { replace: true }
          );
        }}
        onSubmit={() => {
          formInformation.submit();
        }}
        isAllowSubmit={isAllowSubmit}
      />
    </div>
  );
}

export default DeviceDetailPageView;
