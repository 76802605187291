export default {
  cameraGroupPage: {
    breadcrumb: {
      listing: 'Device Group Management',
      create: 'Create Device Group'
    },
    sections: {
      search: {
        title: 'Search Device Group'
      },
      create: {
        title: 'Create Device Group'
      },
      bind: {
        title: 'Bind Device',
        description:
          'Bind cameras via a device serial number, or a list of devices separated by a comma.',
        placeholder: 'e.g. 011030010112z, 0110300101004'
      },
      deviceBind: {
        title: 'Device Binding',
        warning: 'Data has been refreshed, the order and assigned have been reset.'
      },
      cameraBind: {
        title: 'Camera Binding'
      },
      astroDevice: {
        title: 'Astro Device'
      },
      ups: {
        title: 'UPS Monitor'
      },
      eyeviewUser: {
        title: 'Eyeview User Assignment'
      },
      sync: {
        title: 'Sync Camera Service Plan',
        description: 'Set all <0>{{deviceType}}</0> cameras to the same service plan as:'
      },
      assign: {
        title: 'Assign Eyeview User',
        description:
          'Assign device group <0>{{groupName}}</0> to an Eyeview user from the list below'
      },
      delete: {
        title: 'Delete Device Group',
        description: 'Delete the device group <0>{{groupName}}</0>'
      },
      cameraGroupList: {
        title: 'Device Group List'
      },
      general: {
        title: 'General',
        publicIPMismatch:
          'Public IP mismatch detected. Connected devices (camera, Astro, and UPS) have different public IP addresses.'
      },
      autoRefresh: {
        tooltip: {
          editMode: 'In Edit Mode, this section retains current data without checking for updates',
          viewMode: 'In View Mode, this section checks for data changes every minute'
        }
      }
    },
    cameraGroupListingTable: {
      cameraGroupName: 'Device group',
      camera: {
        title: 'Camera',
        render: '<0>{{numberOfOnline}}</0>/{{length}} online'
      },
      ups: 'Ups',
      accountName: 'Customer',
      comment: 'Comment'
    },
    cameraBindingTable: {
      serialNumber: 'Serial Number',
      cameraName: 'Name',
      asc: 'A-Z',
      desc: 'Z-A',
      type: 'Type',
      status: 'Status',
      bvr: 'BVR',
      lastContact: 'Last Contact',
      serviceStartTime: 'Service Start Time',
      publicIP: 'Public IP',
      usageStartTime: 'Usage Start Time',
      extraFooter: {
        label: 'Total: {{camera}} / {{device}}'
      },
      sortBy: 'Sort By:'
    },
    otherDeviceTable: {
      serialNumber: 'Serial Number',
      status: 'Status',
      lastContact: 'Last Contact',
      publicIP: 'Public IP',
      serviceProvider: 'Service Provider',
      assigned: 'Assigned'
    },
    eyeviewUserTable: {
      eyeviewUser: 'Eyeview User'
    },
    emptyTable: {
      title: 'Get Started',
      subTitle: 'By searching group name, customer'
    },
    entity: 'Device Group',
    '404': {
      title: 'Not found',
      subTitle: 'Device Group with this ID has not existed yet'
    },
    errorMessage: {
      cameraGroupId: 'Device Group ID must be a number'
    },
    action: {
      bind: 'Are you sure to bind the camera you have entered?',
      binds: 'Are you sure to bind the cameras you have entered?',
      sync: 'Are you sure to sync camera config from camera',
      assign: {
        from: 'Are you sure to assign device group',
        to: 'to eyeview user'
      }
    },
    tabs: {
      deviceBinding: {
        title: 'Device Binding'
      },
      serviceNote: {
        title: 'Service Notes',
        byDateAsc: 'Sort by date (oldest to newest)',
        byDateDesc: 'Sort by date (newest to oldest)',
        placeholder: {
          search: 'Search service notes by keywords',
          add: 'Put notes related to the service, such as "change the inverters" or "swap camera xyz."'
        },
        serviceEntries: 'Service Entries'
      }
    }
  },
  cameraGroupType: {
    entity: 'Device Group Type'
  },
  camera: {
    entity: 'Camera'
  },
  cameraConfig: {
    entity: 'Camera Config'
  },
  serviceNote: {
    entity: 'Service Notes'
  }
};
