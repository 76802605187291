import { EFeatureKey, ERoleKey, ESubFeatureKey } from 'enums';
import React, { createContext, useContext, useMemo } from 'react';
import { useAuthContext } from './AuthContext';

export type TObjectPermission = {
  [key: string]: { [key: string]: any };
};

const arrFeature = [
  EFeatureKey.ADMIN,
  EFeatureKey.DOMAIN,
  EFeatureKey.ACCOUNT,
  EFeatureKey.DEVICE,
  EFeatureKey.CAMERA_GROUP,
  EFeatureKey.USER,
  EFeatureKey.EYEVIEW_SESSIONS,
  EFeatureKey.SERVER,
  EFeatureKey.SYSTEM
];

const trim = (str: string, char = '_') => {
  let newStr = str;
  if (str.startsWith(char)) newStr = newStr.substring(1);
  if (str.endsWith(char)) newStr = newStr.substring(0, newStr.length - 1);
  return newStr;
};

const splitByRole = (subKey: string) => {
  const arrRole = [
    ERoleKey.VIEW,
    ERoleKey.CREATE,
    ERoleKey.UPDATE,
    ERoleKey.EXECUTE,
    ERoleKey.DELETE
  ];
  const roleKey = arrRole.find((r) => subKey.includes(r));
  if (roleKey) {
    const splitKey = subKey.split(roleKey);
    const subFeature = splitKey.filter((k) => !!k);
    if (subFeature?.length === 1) {
      return [trim(subFeature[0]), roleKey];
    }
    return [roleKey];
  }
  return [];
};

const splitByFeature = (permissionKey: string) => {
  const feature = arrFeature.find((f) => permissionKey.includes(f + '_'));
  if (feature) {
    const t = permissionKey.split(feature + '_');
    return [feature, ...splitByRole(t[1])];
  }
};

const transformPermissionList = (list: string[]) => {
  let obj: TObjectPermission = {};
  if (!list.length) return obj;
  list.forEach((permission) => {
    const keys = splitByFeature(permission) as string[];
    if (keys?.length === 2) {
      const featureKey = keys[0];
      const role = keys[1];
      obj = { ...obj, [featureKey]: { ...obj[featureKey], [role]: 1 } };
    }
    if (keys?.length === 3) {
      const featureKey = keys[0];
      const subFeature = keys[1];
      const role = keys[2];
      obj = {
        ...obj,
        [featureKey]: {
          ...obj[featureKey],
          [subFeature]: { ...(obj[featureKey]?.[subFeature] || {}), [role]: 1 }
        }
      };
    }
  });
  return obj;
};

const PermissionContext = createContext<{
  permissionObj: TObjectPermission;
  permissionArray: any[];
}>(null!);

export const usePermissionContext = () => useContext(PermissionContext);

export const PermissionProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLogged, permissions } = useAuthContext();
  const accessPermission = permissions.map((permission) => permission.name);
  const mappedPermission: TObjectPermission = isLogged
    ? transformPermissionList(accessPermission)
    : {};

  const permissionObj = useMemo(() => mappedPermission, [mappedPermission]);
  const permissionArray = useMemo(
    () => [...arrFeature].filter((f) => mappedPermission[f]?.[ERoleKey.VIEW]),
    [mappedPermission]
  );
  return (
    <PermissionContext.Provider value={{ permissionObj, permissionArray }}>
      {children}
    </PermissionContext.Provider>
  );
};

type TCheckPermissionParams = {
  permissionObj: TObjectPermission;
  routerName?: string;
  role?: ERoleKey;
  subFeature?: ESubFeatureKey;
};

export const checkPermission = ({
  permissionObj,
  routerName,
  role = ERoleKey.VIEW,
  subFeature
}: TCheckPermissionParams) => {
  const splitPath = routerName?.split('/').filter(Boolean) || [];
  const preRouterName = splitPath[0]?.replaceAll('/', '')?.replaceAll('-', '_') || '';
  if (subFeature) {
    return Boolean(permissionObj?.[preRouterName]?.[subFeature]?.[role] || 0);
  }
  return Boolean(permissionObj?.[preRouterName]?.[role] || 0);
};
