import React from 'react';

export function EditIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_215_249"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="21"
      >
        <rect width="21" height="21" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_215_249)">
        <path
          d="M4.375 16.625H5.6L13.1469 9.07812L11.9219 7.85313L4.375 15.4V16.625ZM16.8875 7.80937L13.1688 4.13437L14.3938 2.90938C14.7292 2.57396 15.1413 2.40625 15.6301 2.40625C16.1184 2.40625 16.5302 2.57396 16.8656 2.90938L18.0906 4.13437C18.426 4.46979 18.601 4.87462 18.6156 5.34887C18.6302 5.82254 18.4698 6.22708 18.1344 6.5625L16.8875 7.80937ZM15.6188 9.1L6.34375 18.375H2.625V14.6562L11.9 5.38125L15.6188 9.1Z"
          fill="#4A61E3"
        />
      </g>
    </svg>
  );
}
