import { ERoleKey, ESubFeatureKey } from 'enums';
import { usePermission } from 'hooks/usePermission';
import React, { ReactElement } from 'react';

const recursiveMap = (children: ReactElement): ReactElement[] => {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }
    if ((child as ReactElement).props.children) {
      const props = {
        children: recursiveMap((child as ReactElement).props.children),
        disabled: true
      };
      child = React.cloneElement(child, props);
    }
    return React.cloneElement(child, { disabled: true });
  });
};

const PermissionWrapper = (props: {
  children: React.ReactNode;
  roleKey?: ERoleKey;
  subFeature?: ESubFeatureKey;
  config?: { displayType: 'hidden' | 'disabled' };
}) => {
  const { roleKey, subFeature, config } = props;
  const { allowed } = usePermission(roleKey, subFeature);
  if (allowed) {
    return props.children;
  }
  if (roleKey !== ERoleKey.VIEW && config?.displayType === 'disabled') {
    return recursiveMap(props.children as ReactElement);
  }
  return null;
};

const View = (props: { children: React.ReactNode }) => {
  const { allowed } = usePermission(ERoleKey.VIEW);
  return allowed ? props.children : null;
};

const Create = (props: {
  children: React.ReactNode;
  config?: { displayType: 'hidden' | 'disabled' };
}) => {
  const { allowed } = usePermission(ERoleKey.CREATE);
  if (allowed) {
    return props.children;
  }
  if (props.config?.displayType === 'disabled') {
    return recursiveMap(props.children as ReactElement);
  }
  return null;
};

const Update = (props: {
  children: React.ReactNode;
  subFeature?: ESubFeatureKey;
  config?: { displayType: 'hidden' | 'disabled' };
}) => {
  const { allowed } = usePermission(ERoleKey.UPDATE, props.subFeature);
  if (allowed) {
    return props.children;
  }
  if (props.config?.displayType === 'disabled') {
    return recursiveMap(props.children as ReactElement);
  }
  return null;
};

const Delete = (props: {
  children: React.ReactNode;
  subFeature?: ESubFeatureKey;
  config?: { displayType: 'hidden' | 'disabled' };
}) => {
  const { allowed } = usePermission(ERoleKey.DELETE, props.subFeature);
  if (allowed) {
    return props.children;
  }
  if (props.config?.displayType === 'disabled') {
    return recursiveMap(props.children as ReactElement);
  }
  return null;
};

PermissionWrapper.View = View;
PermissionWrapper.Update = Update;
PermissionWrapper.Create = Create;
PermissionWrapper.Delete = Delete;

export { PermissionWrapper };
