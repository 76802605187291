import React, { useEffect, useRef } from 'react';
import { isValidDependencies } from 'utils/common';

/**
 * Accepts a function that contains imperative, possibly effectful code.
 *
 * Build in React useEffect hook v18
 */
export const useEffectOnce = (effect: React.EffectCallback, deps: React.DependencyList) => {
  const isCallbackExecuted = useRef<boolean>(false);

  useEffect(() => {
    if (!isCallbackExecuted.current && isValidDependencies(deps)) {
      effect();
      isCallbackExecuted.current = true;
    }
  }, deps);
};
