import { useEffect } from 'react';

import useLastCallback from './useLastCallback';

/**
 * A React hook that allows you to set up an interval to repeatedly execute a callback function with a specified delay. The hook ensures the latest version of the callback is always invoked, avoiding stale closures.
 * @param callback The function to execute at each interval. This function will always reflect the latest state and props due to the use of useLastCallback.
 * @param delay The interval delay in milliseconds. A delay of 0 or less is treated as immediate execution. Changing the delay dynamically will restart the interval with the new delay.
 * @param noFirst If true, the callback will not be executed immediately upon setting up the interval. Only subsequent executions will occur.
 */
function useInterval(callback: () => void, delay: number, noFirst = false) {
  const savedCallback = useLastCallback(callback);

  useEffect(() => {
    const id = setInterval(() => savedCallback(), delay);
    if (!noFirst) savedCallback();

    return () => clearInterval(id);
  }, [delay, noFirst]);
}

export default useInterval;
