import React from 'react';
import { Form, FormInstance, Input, Select } from 'antd';
import { DropdownIcon } from 'assets/icons';
import type { TCameraGroupGeneralFieldType, TSelect } from '../cameraGroupPage.types';
import { useTranslation } from 'react-i18next';
import styles from './generalForm.module.scss';
import ValidationUtilities from 'utils/validationUtils';
import { orderBy } from 'lodash';

type Props<T, K> = {
  form: FormInstance<TCameraGroupGeneralFieldType>;
  editMode?: boolean;
  className?: string;
  accountList?: {
    data: K[];
    loading: boolean;
  };
  cameraGroupTypes?: {
    data: T[];
    loading: boolean;
  };
  onSubmit: (values: TCameraGroupGeneralFieldType) => void;
};

const General = <T extends AnyObject, K extends AnyObject>({
  form,
  editMode = false,
  className = '',
  accountList = {
    data: [],
    loading: false
  },
  cameraGroupTypes,
  onSubmit
}: Props<T, K>) => {
  const { t } = useTranslation();

  const filterOption = (input: string, option?: { label: string; value: string | number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Form
      form={form}
      layout="horizontal"
      scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
      onFinish={(values) => onSubmit(values)}
      labelCol={{
        style: {
          width: 130
        }
      }}
      colon={false}
    >
      <div id="deviceGroupPage_generalForm" className={`${styles.container} ${className}`}>
        <Form.Item label={`${t('forms.deviceGroup.label')}`}>
          <div className={`row gx-4 ${styles.inputGroups}`}>
            <Form.Item<TCameraGroupGeneralFieldType>
              name="name"
              className="col-slg-6 col-12 mb-2 mb-slg-0"
              rules={[
                {
                  required: true,
                  message: t('forms.name.required')
                },
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.deviceGroupNameValidationFn({
                      value,
                      errorMessage: t('forms.cameraGroupName.pattern')
                    })
                }
              ]}
            >
              <Input
                className={`${styles.addonData} text-truncate`}
                addonBefore={
                  <div>
                    Name<span className={styles.redAsterisk}>*</span>
                  </div>
                }
              />
            </Form.Item>
            <Form.Item<TCameraGroupGeneralFieldType>
              name="locationNumber"
              className="col-slg-6 col-12 mb-0"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.locationValidationFn({
                      value: value?.trim(),
                      errorMessage: t('forms.locationNumber.pattern')
                    })
                }
              ]}
            >
              <Input
                className={`${styles.addonData} text-truncate`}
                addonBefore="Location Number"
              />
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item<TCameraGroupGeneralFieldType>
          name="accountId"
          label={`${t('forms.accountId.label')}`}
          rules={[{ required: !editMode, message: t('forms.accountId.required') }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            disabled={editMode}
            suffixIcon={
              accountList?.loading ? undefined : <DropdownIcon color="var(--primary-1)" />
            }
            options={
              accountList?.data
                ? orderBy(
                    accountList.data.map(
                      (item: K): TSelect => ({
                        label: item.name,
                        value: item.id
                      })
                    ),
                    'label',
                    ['asc']
                  )
                : undefined
            }
            loading={accountList?.loading}
          />
        </Form.Item>
        <Form.Item<TCameraGroupGeneralFieldType>
          name="cameraGroupTypeId"
          label={`${t('forms.cameraGroupType.label')}`}
          rules={[{ required: true, message: t('forms.cameraGroupType.required') }]}
        >
          <Select
            suffixIcon={
              cameraGroupTypes?.loading ? undefined : <DropdownIcon color="var(--primary-1)" />
            }
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            options={cameraGroupTypes?.data.map((value: T) => ({
              label: value?.name,
              value: value?.id
            }))}
            loading={cameraGroupTypes?.loading}
          />
        </Form.Item>
        <Form.Item<TCameraGroupGeneralFieldType>
          name="comment"
          label={t('forms.comment.label')}
          rules={[
            {
              validator: (_, value: string) =>
                ValidationUtilities.commentValidationFn({
                  value,
                  errorMessage: t('forms.comment.maxLength')
                })
            }
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
        </Form.Item>
        <div className="border-top pt-4 mt-4">
          <Form.Item<TCameraGroupGeneralFieldType>
            name="address"
            label={`${t('forms.address.label')}`}
            rules={[
              {
                validator: (_, value: string) =>
                  ValidationUtilities.addressValidationFn({
                    value,
                    errorMessage: t('forms.address.maxLength')
                  })
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={`${t('forms.latLon.label')}`} className="mb-0">
            <div className={`row gx-4 ${styles.inputGroups}`}>
              <Form.Item<TCameraGroupGeneralFieldType>
                name="latitude"
                className="col-md-6 col-12"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.latitudeValidationFn({
                        value,
                        errorMessage: t('forms.latLon.latValidation')
                      })
                  }
                ]}
              >
                <Input className={`${styles.addonData} text-truncate`} addonBefore="Latitude" />
              </Form.Item>
              <Form.Item<TCameraGroupGeneralFieldType>
                name="longitude"
                className="col-md-6 col-12 mb-0"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.longitudeValidationFn({
                        value,
                        errorMessage: t('forms.latLon.lonValidation')
                      })
                  }
                ]}
              >
                <Input className={`${styles.addonData} text-truncate`} addonBefore="Longitude" />
              </Form.Item>
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default React.memo(General) as typeof General;
